import React from 'react';

const BillsInfo = ({ bill }) => {
    
    return (
        <div className='bills_info table_container'>
            <table>
                <thead>
                    <tr>
                        <th>{bill?.recipe}</th>
                        <th>Cost</th>
                    </tr>
                </thead>

                <tbody>
                    <tr className='sub_thead'>
                        <td>Fixed Costs</td>
                        <td>
                            <span>$</span>
                            <span>{bill?.variableCost?.totalCost}</span>
                        </td>
                    </tr>

                    {
                        bill?.variableCost && Object.keys(bill.variableCost).map((key, index) => {
                           
                            if (key !== 'totalCost') {
                                return <tr key={index}>
                                    <td>
                                        <span>{key}</span>
                                    </td>
                                    <td>
                                        <span>$</span>
                                        <span>{bill.variableCost[key]}</span>
                                    </td>
                                </tr>;
                            }

                            return null
                        })
                    }

                    <tr className='sub_thead'>
                        <td>Ingredients Costs</td>
                        <td>
                            <span>$</span>
                            <span>{bill?.ingredientsCost?.totalCost}</span>
                        </td>
                    </tr>

                    {
                        bill?.ingredientsCost && Object.keys(bill.ingredientsCost).map((key, index) => {
                            if (key !== 'totalCost') {
                                return <tr key={index}>
                                    <td>
                                        <span>{key}</span>
                                    </td>
                                    <td>
                                        <span>$</span>
                                        <span>{bill.ingredientsCost[key]}</span>
                                    </td>
                                </tr>;
                            }

                            return null
                        })
                    }

                </tbody>

                <tfoot>
                    <tr>
                        <th>Total Cost</th>
                        <th>
                            <span>$</span>
                            <span> {bill?.totalCost} </span>
                        </th>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};

export default BillsInfo;
