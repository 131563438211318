import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { decimalValueTrimmer } from '../utils';
import useSubscription from '../hooks/useSubscription';
import useOneTimePayment from '../hooks/useOneTimePayment';
import { getAccessIdToken } from '../firebase/firestore/auth';

const Paddle = window.Paddle;

const Subscription = () => {

  const { data : { is_subscription, subscription_type, subscription_cancel_url, status } } = useSelector(state => state.subscriptionInfo);
  const [isSubscribing, setIsSubscribing] = useState(false)

  const { plans, fetchPlans, getPlans, handleCancelSubscription, handleSubscription } = useSubscription(Paddle, { setIsSubscribing });
  const { getOneTimeProducts, isFetchingOneTime, oneTimeProducts, puchaseOneTimeProduct } = useOneTimePayment(Paddle, { setIsSubscribing });

  useEffect(() => {
    (async() =>{
      try {
        const token = await getAccessIdToken()
        // GET SUBSCRIPTION PLANS
        getPlans(token)
        // GET ONE TIME PRODUCTS 
        getOneTimeProducts(token)
        
      } catch (error) {
        // console.log(error, 'error')
      }
    })()

  }, [getPlans, getOneTimeProducts])

  return (
    <div>
      <h4>Subscription</h4>
        { fetchPlans || isFetchingOneTime ? <h5>Fetching plans...</h5> :
          <div>
            <div className='box'>
              <div className='info_box'>
                <h5>Free</h5>
                  <ul>
                    <li>4 Recipes</li>
                    <li>1 Sub-Recipe</li>
                    <li>Unlimited Ingredients List</li>
                  </ul>
                  {(is_subscription && status === 'active') ||
                    (is_subscription && status === 'deleted') ?
                    <button type='button' disabled={isSubscribing}  className='btn'>
                        Free Plan</button> : 
                    <button type='button' disabled={isSubscribing}  className='btn'>Current Plan</button>}
              </div>

              { oneTimeProducts.map((product) => {
                if(['Lifetime Subscription', 'Lifetime'].includes(product.name)) {
                return (
                <div className='info_box' key={product.id}>
                  <div className='onetime-header'>
                    <h5>{ product.name }</h5>
                    <span className='best-deal'>Best deal</span>
                  </div>
                  <h6>$ {product.base_price } one time payment</h6>
                  <ul>
                      <li>Unlimited Recipes</li>
                      <li>Unlimited Sub-Recipes</li>
                      <li>Unlimited Ingredients List</li>
                      <li>Include taxes in calculations</li>
                  </ul>
                  {
                  !is_subscription ? <button type='button' disabled={isSubscribing}  className='btn' onClick={() => puchaseOneTimeProduct(product.id)}>Upgrade Now</button> :

                  is_subscription && subscription_type === product.name ? 
                  <button type='button' disabled={isSubscribing}  className='btn'>Subscribed</button> : 

                  <button type='button' disabled={isSubscribing}  className='btn'>Upgrade Now</button>
                  }
                </div>)
                }
                return null;
                })  
              }

              { plans.map((plan) =>{
                if(plan.name === 'Yearly'){
                  return (
                  <div key={plan.id} className='info_box'>
                    <h5>{plan.name}</h5>
                    {/* <h6>${decimalValueTrimmer(+plan.recurring_price.USD/12)}/month</h6> */}
                    <h6>${plan.recurring_price.USD}/year</h6>
                    <ul>
                        <li>Unlimited Recipes</li>
                        <li>Unlimited Sub-Recipes</li>
                        <li>Unlimited Ingredients List</li>
                        <li>Include taxes in calculations</li>
                    </ul>
                    {
                    !is_subscription ? <button type='button' disabled={isSubscribing} className='btn' onClick={() =>handleSubscription(plan.id)}>Upgrade Now</button> :
                    
                    is_subscription && subscription_type === plan.name ? <button type='button' disabled={isSubscribing} className='btn'  onClick={() =>handleCancelSubscription(subscription_cancel_url)}>Cancel</button> : 

                    <button type='button' disabled={isSubscribing} className='btn'>Upgrade Now</button>
                    }
                    </div>) }

                    return null;
                    })}
                </div>
          </div>}
    </div>)
}

export default Subscription













    // Send Trascation detail to client email
    //   const getDetail = () =>{
    //     // Enter the product_id in the second parameter to additionally filter the email response to specific product.
    //         Paddle.User.History(email, null, function(response) {	
    //             console.log(response, '>>>>>>')				
    //         if(response.success) {
    //         // Let the user know they'll receive an email with their 
    //         // order history and license codes if their email was matched.
    //         } else {
    //         // Likely a formatting error in the email, vendorID, or ProductID.
    //         }
    //     });
    //   }

    //   getDetail()

    // // Get product price
    // const getProductPrice = () =>{
    //     Paddle.Product.Prices(54805, function(prices) {
    //         console.log({prices});
    //       });
               
    // }; 
    // // getProductPrice()
