import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { addGeneralExpenses } from "../firebase/firestore/restaurant-general";
import { expensesSchema } from "../utils/formSchemas";
import { calCulateTotalCostOfExpenses } from "../utils";
import { useFieldArray, useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addExpenses } from "../state/features/expensesSlice";
import Cleave from 'cleave.js/react';
import { calculateRecipeProfitMargin, calculateRecipeVariableCost, calculateSubRecipeVariableCost } from "../utils/calculations";
import { addReceipes } from "../state/features/recipesSlice";
import { addRecipes, addSubRecipes } from "../firebase/firestore/recipes";
import { addSubReceipes } from "../state/features/subRecipeSlice";



// const CleaveInput = React.forwardRef((props, ref) => {
//   const cleaveRef = useRef();
//   const inputRef = useRef();

//   useEffect(() => {
//     if (cleaveRef.current) {
//       inputRef.current = cleaveRef.current.element;
//     }
//   }, []);

//   return <Cleave {...props} onInit={instance => { cleaveRef.current = instance; }} ref={ref} />;
// });

const Expenses = () => {
  const dispatch = useDispatch();
  const { data: { uid }, signedIn } = useSelector(state => state.authSlice);
  const { data: expenses } = useSelector(state => state.expensesSlice);
  const [totalCost, setTotalCost] = useState(expenses.totalCost);
  const { data: generalData } = useSelector((state) => state.generalData);
  const { data: recipes } = useSelector((state) => state.recipes);
  const { data: subRecipes } = useSelector((state) => state.subRecipe);
  const { taxRate } = useSelector((state) => state.tax);

  const { formState, handleSubmit, control, reset } = useForm({
    defaultValues: expenses,
    resolver: yupResolver(expensesSchema) 
  });

  const { fields, append, remove } = useFieldArray({
    control, // this is coming from useForm
    name: 'other'
  });

  const { errors } = formState;

  // submit expenses
  const handleOnSubmit = async (values) => {
    if (signedIn) {
      try {
            // calculate expenses other varibles cost and variables total cost
            const { otherCost, totalCost } = calCulateTotalCostOfExpenses(values);
          
            setTotalCost(totalCost);
            // Add expenses to firebase collection
            const isAdded = await addGeneralExpenses(uid, { ...values, totalCost, otherCost });

            if (isAdded) {
              // change expenses state in redux store
              dispatch(addExpenses({ ...JSON.parse(JSON.stringify(values)), totalCost, otherCost  }));

              // update subrecipes cost after change the expenses 
              // calcualte and update sub-recipes variables cost
              const subValuesWithVarialbesCost = calculateSubRecipeVariableCost({ recipes : subRecipes}, {...values}, generalData);

              // sub-recipes to the firebase collection with updated variables cost
              const isSubRecipeUpdate =  await addSubRecipes(uid, subValuesWithVarialbesCost);
              
              if(isSubRecipeUpdate){
                // change sub-recipes state in redux store
                dispatch(addSubReceipes(JSON.parse(JSON.stringify({ ...subValuesWithVarialbesCost }))));
              }
              
              // update recipes cost after change the expenses

              // calcualte and update recipes variables cost
              const valuesWithVarialbesCost = calculateRecipeVariableCost({recipes}, {...values}, generalData);
              // calcualte and update profit and margin of recipes
              const valuesWithProfiteAndMargin = calculateRecipeProfitMargin(valuesWithVarialbesCost, taxRate);
              // add updated recipes in the firebase collection with updated variables cost and margin-profit
              const isRecipesUpdate = await addRecipes(uid, valuesWithProfiteAndMargin);
              if (isRecipesUpdate) {
                // change recipes state in redux-state
                  dispatch(addReceipes(JSON.parse(JSON.stringify({ ...valuesWithProfiteAndMargin }))));
              }
            }
        
      } catch (error) {
          toast.error('Something went wrong!')
      }
     
    } else {
        toast.error('You must login to make an entry');
    }
  };

  const handleAddField = () => {
    append({ cost: '', name: '', bill:'' });
  };

  useEffect(() => {
    setTotalCost(expenses?.totalCost);
    reset(expenses);
  }, [expenses, reset]);

  const handleRemoveField = (event, index) => {
    event.preventDefault();
    remove(index);
    handleSubmit(handleOnSubmit)()
  };

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <div className="table_container">
        <table className="expenses_table">
          <thead>
            <tr>
              <th>Bills</th>
              <th>Monthly Cost</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Payroll</td>
              <td>
                <div className='table_field'>
                  <span>$</span>
                  <Controller
                    name="payroll"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <Cleave input
                        className="hide-arrow"
                        placeholder="Enter Amount"
                        options={{ numeral: true, numeralThousandsGroupStyle: 'thousand' }}
                        onChange={event => onChange(event.target.rawValue)}
                        value={value}
                        onBlur={handleSubmit(handleOnSubmit)}
                      />
                    )}
                  />
                </div>
                {errors.payroll && <span className="helper_text">{errors.payroll.message}</span>}
              </td>
            </tr>

            <tr>
              <td>Rent</td>
              <td>
                <div className='table_field'>
                  <span>$</span>
                  <Controller
                    name="rent"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <Cleave
                        className="hide-arrow"
                        placeholder="Enter Amount"
                        options={{ numeral: true, numeralThousandsGroupStyle: 'thousand' }}
                        onChange={event => onChange(event.target.rawValue)}
                        value={value}
                        onBlur={handleSubmit(handleOnSubmit)}
                      />
                    )}
                  />

                </div>
                {errors.rent && <span className="helper_text">{errors.rent.message}</span>}
              </td>
            </tr>

            <tr>
              <td>Water</td>
              <td>
                <div className='table_field'>
                  <span>$</span>
                  <Controller
                    name="water"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <Cleave
                        className="hide-arrow"
                        placeholder="Enter Amount"
                        options={{ numeral: true, numeralThousandsGroupStyle: 'thousand' }}
                        onChange={event => onChange(event.target.rawValue)}
                        value={value}
                        onBlur={handleSubmit(handleOnSubmit)}
                      />
                    )}
                  />

                </div>
                {errors.water && <span className="helper_text">{errors.water.message}</span>}
              </td>
            </tr>

            <tr>
              <td>Electricity</td>
              <td>
                <div className='table_field'>
                  <span>$</span>
                  <Controller
                    name="electricity"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <Cleave className="hide-arrow" placeholder="Enter Amount" options={{ numeral: true, numeralThousandsGroupStyle: 'thousand' }} onChange={event => onChange(event.target.rawValue)} value={value} onBlur={handleSubmit(handleOnSubmit)}
                      />
                    )}
                  />

                </div>
                {errors.electricity && <span className="helper_text">{errors.electricity.message}</span>}
              </td>
            </tr>

            <tr>
              <td>Gas</td>
              <td>
                <div className='table_field'>
                  <span>$</span>
                  <Controller name="gas" control={control} defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <Cleave className="hide-arrow" placeholder="Enter Amount" options={{ numeral: true, numeralThousandsGroupStyle: 'thousand' }} onChange={event => onChange(event.target.rawValue)} value={value} onBlur={handleSubmit(handleOnSubmit)}
                      /> )} />
                </div>
                { errors.gas && <span className="helper_text">{errors.gas.message}</span> }
              </td>
            </tr>

            <tr className="other_row">
              <td colSpan="2">
                <span>Other</span>
              </td>
            </tr>

            {fields.map((field, index) => (
                <tr key={field.id}>
                      <td className="input-container">
                        <div className='table_field'>
                          <button className="remove-btn" onClick={(event) => handleRemoveField(event, index)}>x</button>
                          <Controller name={`other.${index}.name`} control={control} defaultValue=""
                            render={({ field: { onChange, value } }) => (
                              <input placeholder="Expense name" onChange={event => onChange(event.target.value)} value={value} 
                                onBlur={handleSubmit(handleOnSubmit)}
                              />
                            )}
                          />
                        </div>
                      </td>

                      <td>
                        <div className='table_field'>
                          <span>$</span>
                          <Controller
                            name={`other.${index}.cost`} control={control} defaultValue=""
                            render={({ field: { onChange, value } }) => (
                              <Cleave placeholder="Enter Amount" options={{ numeral: true, numeralThousandsGroupStyle: 'thousand' }} onChange={event => onChange(event.target.rawValue)} value={value} onBlur={handleSubmit(handleOnSubmit)}
                              />
                            )}
                          />
                        </div>
                        { errors.other && errors.other[index]?.cost && <span className="helper_text">{errors.other[index].cost.message}</span> }
                      </td>
                    </tr>)
            )}


            <tr>
              <td>
                <button className="add_btn" onClick={handleAddField}>+ Wifi, loans, etc..</button>
              </td>
            </tr>

          </tbody>
          <tfoot>
            <tr>
              <th>Total</th>
              <th>
                <div className="table_field">
                  <span>$</span>
                  <span>{totalCost?.toLocaleString()}</span>
                </div>
              </th>
            </tr>

          </tfoot>
        </table>
      </div>
    </form>
  );
};

export default Expenses;
