import { createSlice } from "@reduxjs/toolkit";


export const STATUS = Object.freeze({
  IDLE : 'idle',
  LOADING: 'loading',
  ERROR: 'error'
})

export const initialAuthState = {
  data : {
    uid: "",
    email: "",
    accessToken: "",
    restaurantName: "",
  },
  status: STATUS.LOADING,
  signedIn: false
};

export const authSlice = createSlice({
  name: "auth",
  initialState : initialAuthState,
  reducers: {
    addRestuarantInfo(state, action ) {
      const { payload } = action;
      return {
        ...state,
           data : {
            uid: payload.uid,
            email: payload.email,
            accessToken: payload.accessToken,
            restaurantName: payload.restaurantName,
        },
        signedIn: payload?.signedIn
      };
    },

    restaurantStatus(state, action) {
      return { ...state, status: action.payload };
    },

    addInitialAuthInfo(state, action){
      return {...action.payload}
    }
  },
});

export const { addRestuarantInfo, restaurantStatus, addInitialAuthInfo } = authSlice.actions;

export default authSlice.reducer;
