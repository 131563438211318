// Importing required libraries
import React, { useEffect, useState, useCallback, useReducer, useRef } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import Select, {components} from 'react-select';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { MdContentCopy } from 'react-icons/md';
import { HiChevronDown } from 'react-icons/hi';
import { RxCross2 } from 'react-icons/rx';
import Cleave from 'cleave.js/react';
import { toast } from 'react-hot-toast';
import IngredientsFieldArray from './IngredientsFieldArray';
import ProfitInfo from './ProfitInfo';
import BillsInfo from './BillsInfo';
import { addReceipes, addReceipesCategories } from '../state/features/recipesSlice';
import { addRecipes, updateCategories } from '../firebase/firestore/recipes';
import { calculateRecipeIngredientCost, calculateRecipeVariableCost, calculateRecipeProfitMargin } from '../utils/calculations';
import { generateRecipesSearchOptions } from '../utils/generateData';
import { recipesSchema } from '../utils/formSchemas';
import { updateSingleFieldUser } from '../firebase/firestore/auth';
import { FilterDropdown } from './FilterDropdown';
import info from '../assets/icons/info.png';
import SortingFilter from './SortingFilter';


export const RECIPES_ACTION_TYPE = {
    CURRENT_RECIPES: 'CURRENT_RECIPES',
    REST_RECIPES: 'REST_RECIPES',
    TOTAL_RECIPES: 'TOTAL_RECIPES'
  };
  
  const initialRecipesDataFlow = {
    totalRecipes: [],
    currenRecipes: [],
    restRecipes: [],
  };
  
  const recipesReducer = (state, action) => {
    switch (action.type) {
      case "TOTAL_RECIPES":
        return { ...state, totalRecipes: action.payload };
  
      case "REST_RECIPES":
        return { ...state, restSubRecipes: action.payload };
  
      case "CURRENT_RECIPES":
        return { ...state, currenRecipes: action.payload };
  
      default:
        return state;
    }
  };


// Main component
const Recipes = () => {

    const [showPrepPopup, setShowPrepPopup] = useState(false);
    const [showCookPopup, setShowCookPopup] = useState(false);

    // Initializing required variables
    const dispatch = useDispatch();
    const { data: recipes, categories } = useSelector((state) => state.recipes);
    const { data: { uid }, signedIn } = useSelector((state) => state.authSlice);
    const { data: expenses, expensesIsEmpty } = useSelector((state) => state.expensesSlice);
    const { data: generalData, generalDataIsEmpty } = useSelector((state) => state.generalData);
    const { recipesIngredientOptions } = useSelector((state) => state.ingredientOptions);
    const { recipesIngredientList } = useSelector(state => state.ingredientList);
    const { taxRate } = useSelector((state) => state.tax);
    const isOpenRef = useRef(null);
    const [showFilter, setShowFilter] = useState('');
    const [appylyFilter, setApplyFilter] = useState({ sort: false, category: [], defaultData: [], sortData: [] });
    const { data: { is_subscription } } = useSelector(state => state.subscriptionInfo);

    // local states data for performing filter
    const [recipesData, recipesDispatcher] = useReducer(recipesReducer, initialRecipesDataFlow);
    const [selectRecipe, setSelectRecipe] = useState('');
    const [selectCategory, setSelectCategory] = useState('') 

    const [closeMenuOption, setCloseMenuOption] = useState(false);

    // Initializing form variables
    const { control, register, handleSubmit, setValue, setFocus, reset } = useForm({
        defaultValues: {
            recipes: recipes.map(recipe => ({
                ...recipe,
                preparationTime: recipe.preparationTime || null,
                cookingTime: recipe.cookingTime || null
            }))
        },
        resolver: yupResolver(recipesSchema)
    });

    // Initializing fields array
    const { fields: tableFields, remove: rowRemove } = useFieldArray({
        control,
        name: 'recipes'
    });

    // Function to add another row
    const addAnotherRow = () => {

        if (!is_subscription && recipes.length >= 4) {
            toast.error('You can not add another recipe with your current plan');
            return;
        }

        const newData = {
            recipe: "",
            category: '',
            ingredients: [],
            preparationTime: null,
            cookingTime: null,
            ingredientsCost: { totalCost: 0 },
            variableCost: { totalCost: 0 },
            totalCost: 0,
            sellingPrice: null,
            profit: 0,
            margin: null,
            profitDetails: null,
            createdAt: new Date().toUTCString()
        };
        dispatch(addReceipes(JSON.parse(JSON.stringify({ recipes: [newData, ...recipes] }))));
    };

    // Copy row values means duplicate the row
    const copyRow = (value) =>{
        
        if (!is_subscription && recipes.length >= 4) {
            toast.error('You can not add another recipe with your current plan');
            return;
        }

        let indexOfCopyRow= 0 ;
        for (let index = 0; index < recipes.length; index++) {
              if(new Date(value.createdAt).getTime() === new Date(recipes[index].createdAt).getTime()){
                indexOfCopyRow = index;
              }
        }

        const newSubRecipes = [...recipes];
        newSubRecipes.splice(indexOfCopyRow, 0, { ...value });
        dispatch(addReceipes(JSON.parse(JSON.stringify({ recipes: [...newSubRecipes] }))));
    }


    // Function to remove row
    const handleRemoveTableRow = (index) => {
        // delete table row 
        rowRemove(index);

        // submit form and update table 
        handleSubmit(onSubmit)();

    };


    // Function to submit the form
    const onSubmit = async (values) => {

        try {

            if (signedIn) {

                if (expensesIsEmpty || generalDataIsEmpty) {
                    toast.error("Please fill expenses and general data before to continue");
                }
                // Get all data from current display values and rest data, it is important for filtering. 
                const totalValues = {}
                totalValues.recipes = [...values.recipes, ...recipesData.restRecipes]
                // calculate recipes ingrdients cost
                const valuesWithIngredientsCost = calculateRecipeIngredientCost({ recipes: JSON.parse(JSON.stringify(totalValues.recipes)) }, recipesIngredientList);

                // calculate recipes varible cost
                const valuesWithVarialbesCost = calculateRecipeVariableCost(valuesWithIngredientsCost, expenses, generalData);

                // calculate recipes margin and profit cost
                const valuesWithProfiteAndMargin = calculateRecipeProfitMargin(valuesWithVarialbesCost, taxRate);

                const isAdd = await addRecipes(uid, valuesWithProfiteAndMargin);

                if (isAdd) {
                    updateSingleFieldUser(uid, 'no_recipes', valuesWithProfiteAndMargin.recipes.length);
                    dispatch(addReceipes(JSON.parse(JSON.stringify({ ...valuesWithProfiteAndMargin }))));
                }

            } else {
                toast.error('You must login to make an entry');
            }

        } catch (error) {
            // console.log(error, 'error');
        }


    };


    // Add new ingredient category
    const handleAddCategory = async (event, new_category) => {
        event.stopPropagation();
        try {
            if (!signedIn) {
                toast.error('Please do login to add new category!');
            }
            await updateCategories(uid, { recipeCategories: [...categories, { label: new_category, value: new_category }] });
            // dispatch(addReceipesCategories([...categories, {label: new_category, value: new_category}]))
        } catch (error) {
            toast.error('Something went wrong!');
        }
    };


    
    // Function for toggling the Sorting Filters
    const handleShowFilter = (value) => {
        if (showFilter === value) {
            setShowFilter('');
            return
        }
        setShowFilter(value);
    };


    // Handle sorting in current recipes
    const handleSorting = (sortNum) => {
        let filterWith = showFilter;
    
        if (sortNum === 1) {
            const sortData = JSON.parse(JSON.stringify(recipesData.currenRecipes)).sort((a, b) => a[filterWith] - b[filterWith]);
            setApplyFilter({ ...appylyFilter, defaultData: [...recipes], sort: true, sortData });
            // setValue('recipes', [...sortData]);

            reset({recipes : [...sortData]}) // reset form values after applying sorting
            recipesDispatcher({ type: RECIPES_ACTION_TYPE.CURRENT_RECIPES, payload: [...sortData] });

            setShowFilter('');
        } else {
            const sortData = JSON.parse(JSON.stringify(recipesData.currenRecipes)).sort((a, b) => b[filterWith] - a[filterWith]);
            setApplyFilter({ ...appylyFilter, defaultData: [...recipes], sort: true, sortData });
            reset({recipes : [...sortData]}) // reset form values after applying sorting
            recipesDispatcher({ type: RECIPES_ACTION_TYPE.CURRENT_RECIPES, payload: [...sortData] });
            setShowFilter('');
        }
    };

    // function for handle filter and table data
    const handleFilter = useCallback(() => {

        if(selectCategory || selectRecipe){
      
          let filteredData = []
          let restData = []
      
          if(selectCategory && selectRecipe){
            // Searched data if selectCategory and select ingredient both are selected
            filteredData = recipes.filter((recipe) =>{
                return(recipe.recipe === selectRecipe &&  recipe.category === selectCategory) ? true : restData.push(recipe) && false })
            } else {

              if(selectRecipe){
                  // Searched data if ingredient is select only
                  filteredData = recipes.filter((recipe) =>{
                  return recipe.recipe === selectRecipe ? true : restData.push(recipe) && false })
      
              }else{
                  // Searched data if category is select only
                  filteredData = recipes.filter((recipe) =>{
                    return recipe.category === selectCategory ? true : restData.push(recipe) && false })
              }
      
            }

            reset({recipes : [...filteredData]}) // reset form values after applying filters
            recipesDispatcher({ type: RECIPES_ACTION_TYPE.CURRENT_RECIPES, payload: [...filteredData] });
            recipesDispatcher({ type: RECIPES_ACTION_TYPE.REST_RECIPES, payload: [...restData] });
            recipesDispatcher({ type: RECIPES_ACTION_TYPE.TOTAL_RECIPES, payload: recipes });
      
      
        }else{
      
            reset({recipes : [...recipes]})  // reset form values after changing recipes data
            recipesDispatcher({ type: RECIPES_ACTION_TYPE.CURRENT_RECIPES, payload: recipes });
            recipesDispatcher({ type: RECIPES_ACTION_TYPE.REST_RECIPES, payload: [] });
            recipesDispatcher({ type: RECIPES_ACTION_TYPE.TOTAL_RECIPES, payload: recipes });
      
        }
      
        }, [selectCategory, selectRecipe, recipes, reset]);
      
    useEffect(() => {
        handleFilter();
    }, [recipes, handleFilter]);

    // Category Options with Delete button
    const SelectMenuButton = (props) => {

        // function for delete recipe categories
        const handleDeleteCategory = async (event, deleted_category) => {
            if (signedIn) {
                event.stopPropagation();

                const new_categories = categories.filter((category) => (category.label !== deleted_category));

                await updateCategories(uid, { recipeCategories: [...new_categories] });
                dispatch(addReceipesCategories([...new_categories]));
            } else {
                toast.error('You must login to delete any category');
            }

        };


        return (
            <components.Option  {...props}>
                <div className="flex" style={{ justifyContent: "space-between" }}>
                    <div>{props.children}</div>
                    <button type='button' className='remove-btn' onClick={(event) => handleDeleteCategory(event, props.children)}><RxCross2 /></button>
                </div>
            </components.Option >
        );
    };

    return (
        <>
            <div>
                <form className='table_container' onSubmit={handleSubmit(onSubmit)}>
                    <table className='recipes_table'>
                        <thead>
                            <tr>
                                <th>
                                    <div className="filter_item flex">
                                        <span>Recipes</span>
                                        <FilterDropdown options={generateRecipesSearchOptions(recipes)} selectValue={setSelectRecipe} value={selectRecipe} />
                                    </div>
                                </th>
                                <th>
                                    <div className="filter_item flex">
                                        <span>Category</span>
                                        <FilterDropdown options={categories} selectValue={setSelectCategory} value={selectCategory} />
                                    </div>
                                </th>
                                <th>Ingredients</th>
                                <th>
                                    <div>
                                        <span>Preparation <br />Time
                                            <img
                                                src={info}
                                                className='infoIcon'
                                                alt="Info"
                                                onMouseOver={() => setShowPrepPopup(true)}
                                                onMouseEnter={() => setShowPrepPopup(true)}
                                                onMouseLeave={() => setShowPrepPopup(false)}
                                            />
                                        </span>
                                    </div>
                                    {showPrepPopup && (
                                        <div className="popup">
                                            <p>Human hours involved preparing this recipe. </p>
                                        </div>
                                    )}
                                </th>
                                <th>
                                    <div>
                                        <span>Cooking <br />Time
                                            <img
                                                src={info}
                                                className='infoIcon'
                                                alt="Info"
                                                onMouseEnter={() => setShowCookPopup(true)}
                                                onMouseLeave={() => setShowCookPopup(false)}
                                            />
                                        </span>
                                    </div>
                                    {showCookPopup && (
                                        <div className="popup">
                                            <p>Enter the amount of time required to cook this recipe, considering the cooking method used: oven, stove, etc.</p>
                                        </div>
                                    )}
                                </th>
                                <th align='center' style={{ borderTopRightRadius: "8px" }} className='  separated_left_cell' >
                                    <div className="filter_item flex" style={{ justifyContent: "center" }}>
                                        <span>Total Cost</span>
                                        <button type='button' onClick={() => handleShowFilter("totalCost")}>
                                            <HiChevronDown />
                                        </button>
                                    </div>
                                    {
                                        showFilter === "totalCost" && (
                                            <SortingFilter setShowFilter={setShowFilter} handleSorting={handleSorting} />
                                        )
                                    }
                                </th>
                                <th className='empty_cell'></th>
                                <th style={{ borderTopLeftRadius: "8px" }} className='separated_right_cell'>
                                    <div className="filter_item flex">
                                        <span>Selling Price</span>
                                        <button
                                            type='button'
                                            onClick={() => handleShowFilter("sellingPrice")}
                                        >
                                            <HiChevronDown />
                                        </button>
                                    </div>
                                    {
                                        showFilter === "sellingPrice" && (
                                            <SortingFilter setShowFilter={setShowFilter} handleSorting={handleSorting} />
                                        )
                                    }
                                </th>
                                <th align='center'>
                                    <div className="filter_item flex" style={{ justifyContent: "center" }}>
                                        <span>Profit</span>
                                        <button
                                            type='button'
                                            onClick={() => handleShowFilter("profit")}
                                        >
                                            <HiChevronDown />
                                        </button>
                                    </div>
                                    {
                                        showFilter === "profit" && (
                                            <SortingFilter setShowFilter={setShowFilter} handleSorting={handleSorting} />
                                        )
                                    }
                                </th>
                                <th>
                                    <div className="filter_item flex">
                                        <span>Margin</span>
                                        <button
                                            type='button'
                                            onClick={() => handleShowFilter("margin")}
                                        >
                                            <HiChevronDown />
                                        </button>
                                    </div>
                                    {
                                        showFilter === "margin" && (
                                            <SortingFilter setShowFilter={setShowFilter} handleSorting={handleSorting} />
                                        )
                                    }
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className='add_row'>
                                <td colSpan="5">
                                    <button type='button' className='add_btn' onClick={addAnotherRow}>
                                        + Add
                                    </button>
                                </td>
                                <td style={{ backgroundColor: "#f8f8fa" }} className='separated_left_cell'></td>
                                <td className="empty_cell"></td>
                                <td className='separated_right_cell'></td>
                                <td colSpan="2" style={{ backgroundColor: "#f8f8fa" }}></td>
                                <td style={{ backgroundColor: "#f8f8fa" }}></td>
                            </tr>

                            {tableFields.map((field, index) => {
                                return (<tr key={field.id}>
                                    <td className='recipes_cell'>
                                        <input type="text"  {...register(`recipes.${index}.recipe`)} onBlur={handleSubmit(onSubmit)} placeholder="Recipe name"
                                            style={{
                                                fontWeight: 600, fontSize: '0.875rem', color: '#54585B'
                                            }}
                                        />
                                    </td>

                                    <td>
                                        <Controller
                                            control={control}

                                            name={`recipes.${index}.category`}

                                            render={({ field: { onChange, value, name, ref } }) => {

                                                let selectValue = categories.find(c => c?.value === value) === undefined ? '' : categories.find(c => c?.value === value);
                                                return <Select value={selectValue} onChange={val => { onChange(val?.value); handleSubmit(onSubmit); }} className="select" classNamePrefix="select" isDisabled={false} isClearable={true} isSearchable={true} components={{ Option: SelectMenuButton }} options={categories} onBlur={handleSubmit(onSubmit)} placeholder="Select Category"
                                                    noOptionsMessage={({ inputValue }) => inputValue ? (
                                                        <div>
                                                            <span>{inputValue}</span>
                                                            <button type='button' className='add_btn' onClick={(event) => {
                                                                dispatch(addReceipesCategories([...categories, { label: inputValue, value: inputValue }]));
                                                                handleAddCategory(event, inputValue);
                                                            }}>
                                                                + Add
                                                            </button>
                                                        </div>
                                                    ) : "Type and add category"} />;
                                            }} />
                                    </td>


                                    <td>
                                        <table className='ingredients_table'>
                                        <IngredientsFieldArray fieldName={`recipes`} index={index}  isOpenRef={isOpenRef} 
                                            handleForm={{ handleSubmit, onSubmit, control, register, setFocus, setValue }} closeMenuOption={closeMenuOption} setCloseMenuOption={setCloseMenuOption} ingredientOptions={recipesIngredientOptions}  ingredientList={recipesIngredientList}  />
                                        </table>
                                    </td>

                                    <td align='center'>
                                        <div className='table_field' style={{ color: '#54585B' }}>
                                            <input
                                                type="number"
                                                {...register(`recipes.${index}.preparationTime`)}
                                                onBlur={handleSubmit(onSubmit)}
                                                step='any'
                                                placeholder="0 min"
                                                style={{ width: "40%" }}
                                            />
                                            {recipesData.currenRecipes[index]?.preparationTime && <span>min</span>}
                                        </div>
                                      
                                    </td>

                                    <td align='center'>
                                        <div className='table_field' style={{ color: '#54585B' }}>
                                            <input
                                                type="number"
                                                {...register(`recipes.${index}.cookingTime`)}
                                                onBlur={handleSubmit(onSubmit)}
                                                step='any'
                                                placeholder="0 min"
                                                style={{ width: "40%" }}
                                            />
                                            {recipesData.currenRecipes[index]?.cookingTime > 0 && <span>min</span>}
                                           
                                        </div>
                                    </td>

                                    <td align='center' className='separated_left_cell' style={{ backgroundColor: "#f8f8fa" }}>
                                        <div className='cost'>
                                            <div className="table_field">
                                                <span>$</span>
                                                <b>{recipesData.currenRecipes[index]?.totalCost ? recipesData.currenRecipes[index]?.totalCost.toLocaleString() : ''}</b>
                                            </div>
                                            <BillsInfo bill={recipesData.currenRecipes[index]} />
                                        </div>
                                    </td>

                                    <td className="empty_cell"></td>

                                    <td align='center' className='separated_right_cell'>
                                        <div className='table_field'>
                                            <span>$</span>
                                            <Cleave
                                                options={{
                                                    numeral: true,
                                                    numeralThousandsGroupStyle: 'thousand'
                                                }}
                                                onChange={(e) => {
                                                    // parse the value to a number and set it to the state
                                                    const value = parseFloat(e.target.value.replace(/,/g, ''));
                                                    if (!isNaN(value) || e.target.value === '') {
                                                        setValue(`recipes.${index}.sellingPrice`, value);
                                                    }
                                                }}
                                                onBlur={handleSubmit(onSubmit)}
                                                placeholder="Price"
                                                value={recipesData.currenRecipes[index]?.sellingPrice || ''}
                                                style={{
                                                    fontSize: '0.875rem',
                                                    color: '#54585B',
                                                    fontWeight: 400,
                                                    width: "50%"
                                                }}
                                            />
                                        </div>
                                        
                                    </td>

                                    <td align='center' style={{ backgroundColor: "#f8f8fa" }}>
                                        <div className='cost'>
                                            <div className='table_field'>
                                                <span>$</span>
                                                <b>{recipesData.currenRecipes[index]?.profit ? recipesData.currenRecipes[index]?.profit.toLocaleString() : ''}</b>
                                            </div>
                                            <ProfitInfo recipe={recipesData.currenRecipes[index]} />
                                        </div>
                                    </td>

                                    <td style={{ backgroundColor: "#f8f8fa" }}>
                                        <div className='table_field'>

                                            {/* <span>{isFinite(recipes[index]?.margin) ? recipes[index]?.margin : 0}</span> */}
                                            <span>{isFinite(recipesData.currenRecipes[index]?.margin) ? recipesData.currenRecipes[index]?.margin : 0}</span>

                                            <span>%</span>
                                        </div>
                                    </td>

                                    <td style={{ backgroundColor: "#f8f8fa" }}>
                                        <div className="table_btns flex">
                                            <button title='Copy'>
                                                <MdContentCopy onClick={() =>copyRow(recipesData.currenRecipes[index])} />
                                            </button>
                                            <button title='Delete'>
                                                <RiDeleteBin5Line onClick={() => handleRemoveTableRow(index)} />
                                            </button>
                                        </div>
                                    </td>

                                </tr>);
                            })}

                        </tbody>
                    </table>
                </form>
            </div>
        </>
    );
};

export default Recipes;

