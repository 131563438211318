import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import AuthDialog from './AuthDialog';
import UserDrawer from './UserDrawer';
import { useDispatch, useSelector } from 'react-redux';
import { checkUserAuth } from '../firebase/firestore/auth';
import { STATUS } from '../state/features/authSlice';
import useScrollDisable from '../hooks/useScrollDisable';
import Loader from './Loader';
import { SUBSCRIPTION_STATUS } from '../state/features/subscriptionSlice';

const Header = () => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const dispatch = useDispatch();
    const { status, signedIn } = useSelector(state => state.authSlice);
    const { fetch_status } = useSelector(state => state.subscriptionInfo);

    const toggleDrawer = (open) => {
        setIsDrawerOpen(open);
    };

    useEffect(() => {
        // Check user authentication and get user info from restaurant-users collection
        dispatch(checkUserAuth());
    }, [dispatch]);

    // disable the body-scroll when Preloader is spinning.
    useScrollDisable(status === STATUS.LOADING || fetch_status === SUBSCRIPTION_STATUS.LOADING);

    return (
        <>
            {/* Header */}
            <>
                {status === STATUS.LOADING ? <Loader loaderBox={true} loaderSize="100px" /> :
                    <header>
                        {
                            signedIn ? null : (
                                <div className='top_header'>
                                    <div className='container'>
                                        <div className='navbar'>
                                            <p>This is a demo. Sign up to create your own recipes!</p>
                                            <button
                                                className='btn outlined'
                                                onClick={() => setIsDialogOpen(true)}
                                            >
                                                Let's Start
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        }

                        <div className='bottom_header'>
                            <div className='container'>
                                <div className='navbar'>
                                    <div className='navlinks'>
                                        <NavLink to="/">General</NavLink>
                                        <NavLink to="/recipes">Recipes</NavLink>
                                    </div>

                                    {
                                        signedIn ? (
                                            <div>
                                                <button
                                                    className='settings_btn'
                                                    onClick={() => toggleDrawer(true)}
                                                    title='Open Settings'
                                                >
                                                    <img src="/images/chef_emoji.jpg" alt="user-img" />
                                                </button>
                                            </div>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </header>
                }
            </>

            {/* Dialog */}
            <AuthDialog isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen} />

            {/* Drawer */}
            {
                signedIn ? (
                    <UserDrawer
                        isDrawerOpen={isDrawerOpen}
                        toggleDrawer={toggleDrawer}
                    />
                ) : null
            }

        </>
    );
};

export default Header;
