import { useCallback, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import axios from "axios";
import { SUBSCRIPTION_STATUS, addSubscriptionDetails, fetchingSubscriptionDetails } from "../state/features/subscriptionSlice";
import { addSubscription, updateSubscription } from "../firebase/firestore/restaurant-general";
import { updateUser } from "../firebase/firestore/auth";

const useSubscription = (Paddle) => {

    const dispatch = useDispatch();
    const { data: { email, uid }, signedIn } = useSelector(state => state.authSlice);

    const [plans, setPlans] = useState([])
    const [fetchPlans, setFetchPlans] = useState(true)

    // Get subscritpionId and subscritpion details
    const getSubscriptionDetails = (checkoutId) =>{
        dispatch(fetchingSubscriptionDetails(SUBSCRIPTION_STATUS.LOADING))

        Paddle.Order.details(checkoutId, async function(data) {
            if (!!data) {
            // Order data, downloads, receipts etc... available within 'data' variable
            const { order } = data
            const { currency, is_subscription, order_id, receipt_url, subscription_id  } = order;

            try {
                // Fetcing subscription details from backend
                const response = await axios.post( process.env.REACT_APP_BACKEND_URL + '/paddle/subscriptionInfo', { subscription_id })

                if(response.data.success){
                    const { signup_date, subscription_id, cancel_url, update_url, state, user_email, next_payment } = response.data.response[0];

                    const res =  await updateSubscription(uid, { 
                        date_of_subscription : new Date(signup_date).toUTCString(),
                        next_date_of_subscription : new Date(next_payment.date).toUTCString(),
                        subscription_id,
                        subscription_cancel_url: cancel_url,
                        subscription_update_method_url: update_url,
                        status : state,
                        customer_email : user_email,
                        currency,
                        is_subscription,
                        order_id,
                        receipt_url
                    });


                    // update user document and add is_subscription value
                    updateUser(uid, { is_subscription, subscription_id } )

                    if(res) {
                        dispatch(addSubscriptionDetails({ 
                            date_of_subscription : new Date(signup_date).toUTCString(),
                            next_date_of_subscription : new Date(next_payment.date).toUTCString(),
                            subscription_id,
                            subscription_cancel_url: cancel_url,
                            subscription_update_method_url: update_url,
                            status : state,
                            customer_email : user_email,
                            currency,
                            is_subscription,
                            order_id,
                            receipt_url
                        }))

                        toast.success("You are subscribed!")
                    }
                }
                dispatch(fetchingSubscriptionDetails(SUBSCRIPTION_STATUS.IDLE));
            } catch (error) {
                dispatch(fetchingSubscriptionDetails(SUBSCRIPTION_STATUS.ERROR))
                toast.error('Something went wrong')
            }
            } else {
                // Order processing delay - order details cannot be retrieved at the moment
                toast.error("Subscription details cannot be retrived at the moment. But don't worry after some time you may be subscribed")
                dispatch(fetchingSubscriptionDetails(SUBSCRIPTION_STATUS.ERROR));
            }
        });
    }

    // function for handle subscription
    const handleSubscription = (planId) => {
        if(signedIn){
            // Start the subscription checkout process
            Paddle.Checkout.open({
                product: planId, 
                email: email, 
                passthrough: uid,
                successCallback: async (data) =>{
                const { checkout, product } = data;

                // update user document
                await updateUser(uid, { subscription_type: product.name } )

                // add some subscription details to the firebase collection 
                await addSubscription(uid, { passthrough : checkout.passthrough, subscription_type: product.name });
            
                // ADD SUBSCRIPTION DETAILS IN REDUX
                dispatch(addSubscriptionDetails({ passthrough : checkout.passthrough, title: product.name, subscription_type : product.name }))
                    
                // get and set Subscription detail in redux and firebase collection
                getSubscriptionDetails(checkout.id);

                },
                closeCallback: (data) => {
                    //ADD YOUR EXTRA LOGIC TO SEND TO BACKEND
                    if(data && Object.keys(data).length) {
                        Paddle.Order.details(data.checkout.id, async function(data) {
                            // console.log(data)
                        })
                    }   
                }
            });
        }else{
            toast.error("To get a subscription. Please do singin first")
        }
    };

    
    // Cancel subscription 
    const handleCancelSubscription = (url) =>{
        Paddle.Checkout.open({
            override: url,
            successCallback: async (data) => {
            try {
                toast.success('Your subscription is cancelled. You will not charged again.')
                // dispatch(updateSubscription)
                const res =  await updateSubscription(uid, { 
                    next_date_of_subscription : '',
                    subscription_cancel_url: '',
                    subscription_update_method_url: '',
                    status : 'deleted',
                });

                if(res) {
                    dispatch(addSubscriptionDetails({ 
                        next_date_of_subscription : '',
                        subscription_cancel_url: '',
                        subscription_update_method_url: '',
                        status : 'deleted',
                    }))
                }
            } catch (error) {
                // console.log(error, 'error')
            }     
        }
        }); 
    }

    // Sorting plan to insure that free always should in first index
    const plansSort = (a, b) => {
        if (a.name === 'Free ') {
            return -1; // Free should be first
        } else if (b.name === 'Free ') {
            return 1; // Free should be first
        } else if (a.name === 'Premium') {
            return 1; // Premium should be last
        } else if (b.name === 'Premium') {
            return -1; // Premium should be last
        } else {
            return 0; // Maintain the original order for other plans
        }
    };

    const getPlans = useCallback(async(idToken) => {
        let planArr = ["Premium", "Free", "Yearly"]
        try {
            const response = await axios.get(process.env.REACT_APP_BACKEND_URL + '/paddle/plans', {
                headers : {
                    Authorization : `Bearer ${idToken}`
                }
            })

            if(response.data.success){
                const plans = response.data.response.filter((plan) => planArr.includes(plan.name))

                const sortedPlans = plans.sort(plansSort);
                
                setPlans(sortedPlans)
            }
            setFetchPlans(false)
        } catch (error) {
            toast.error('Something went wrong! Currently we are not be able to fetch plans')
            setFetchPlans(false)
        }
    }, [])

  return {
    plans, setPlans, fetchPlans, setFetchPlans, getPlans, handleCancelSubscription, getSubscriptionDetails, handleSubscription
  }
}

export default useSubscription
