import React, { memo, useCallback, useEffect, useReducer, useState } from 'react';
import Select from 'react-select';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { RxCross2 } from 'react-icons/rx';
import { v4 as uuidv4 } from 'uuid';
import { ingredientSchema } from '../utils/formSchemas';
import { addIngredients as addIngredientsToCollection } from '../firebase/firestore/restaurant-general';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import { calculateIngredientNetPrice, calculateRecipeIngredientCost, calculateRecipeProfitMargin, calculateRecipeVariableCost, calculateSubRecipeIngredientCost, calculateSubRecipeVariableCost, generateIngredientPriceListForSubrecipes, generateIngredientListForRecipes } from '../utils/calculations';
import { addIngredients, addIngredientsCategories } from '../state/features/ingredientSlice';
import { updateRecipeIngredientList, updateSubRecipeIngredientsList } from '../state/features/IngredientsPriceSlice';
import { addRecipeIngredientOptions, addSubRecipeIngredientsOptions } from '../state/features/IngredientOptionsSlice';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { addSubReceipes } from '../state/features/subRecipeSlice';
import { addRecipes, addSubRecipes, updateCategories } from '../firebase/firestore/recipes';
import { addReceipes } from '../state/features/recipesSlice';
import Cleave from 'cleave.js/react';
import { FilterDropdown } from './FilterDropdown';
import { components } from 'react-select';
import { generateIngredientsSearchOptions } from '../utils/generateData';


export const INGREDIENTS_ACTION_TYPE = {
  CURRENT_INGREDIENT: 'CURRENT_INGREDIENT',
  REST_INGREDIENT: 'REST_INGREDIENT',
  TOTAL_INGREDIENT: 'TOTAL_INGREDIENT'
};

const initialIngredientsDataFlow = {
  totalIngredients: [],
  currentInredients: [],
  restIngredients: [],
};

const ingredientsReducer = (state, action) => {
  switch (action.type) {
    case "TOTAL_INGREDIENT":
      return { ...state, totalIngredients: action.payload };

    case "REST_INGREDIENT":
      return { ...state, restIngredients: action.payload };

    case "CURRENT_INGREDIENT":
      return { ...state, currentInredients: action.payload };

    default:
      return state;
  }
};

const Ingredient = () => {
  const dispatch = useDispatch();
  const { data: { uid }, signedIn } = useSelector((state) => state.authSlice);
  const { data: ingredients, categories } = useSelector((state) => state.ingredients);
  const { data: subRecipes } = useSelector((state) => state.subRecipe);
  const { data: recipes } = useSelector((state) => state.recipes);
  const { data: expenses } = useSelector((state) => state.expensesSlice);
  const { data: generalData } = useSelector((state) => state.generalData);
  const { taxRate } = useSelector((state) => state.tax);
  const { RestaurantMetricUnits, RestaurantImperialUnits } = useSelector(state => state.units);

  // local states data for performing filter
  const [ingredientsData, ingredientDispatch] = useReducer(ingredientsReducer, initialIngredientsDataFlow);
  const [selectIngredient, setSelectIngredient] = useState('');
  const [selectCategory, setSelectedCategory] = useState('') 


  const { handleSubmit, control, register, setValue, reset } = useForm({
    defaultValues: {
      ingredients
    },
    resolver: yupResolver(ingredientSchema)
  });

  // Initializing fields array
  const { fields, prepend, remove: rowRemove } = useFieldArray({
    control,
    name: 'ingredients'
  });


  // Function to submit the form
  const onSubmit = async (values) => {

    try {
      if (signedIn) {
          // Get all data from current display values and rest data, it is important for filtering. And unique if it has not present 
          let uniqueIngredients = [] // it is for checking that duplicate entries
          const totalValues = {}
          totalValues.ingredients = [...values.ingredients, ...ingredientsData.restIngredients].map((ingredient) =>{
            return !ingredient.uniqueId ? { ...ingredient, uniqueId:  uuidv4() } : { ...ingredient }
          }).filter((ingredient) =>{
            return uniqueIngredients.includes(ingredient.ingredient) ? false : uniqueIngredients.push(ingredient.ingredient) && true
          })

        
          
          // calculate ingredients netPrice
          const ingredientWithNetPrice = calculateIngredientNetPrice(totalValues.ingredients);

          const isAdded = await addIngredientsToCollection(uid, { ingredients: [...ingredientWithNetPrice] });

        if (isAdded) {
          // dispatch(addIngredients({ ingredients: ingredientWithNetPrice }));

          // update sub-recipes data
          // calculate subrecipes ingrdients cost
          // generete ingredients price list for sub-recipes
          const subRecipeIngedientsPriceList = generateIngredientPriceListForSubrecipes(ingredientWithNetPrice);

          // // update ingredients price list for recipe in redux store
          // dispatch(updateSubRecipeIngredientsList(subRecipeIngedientsPriceList));

          // Check selected unit and change sub-recipes unit if user has changed unit options and also add unique Id to the sub-recipes
          let newSubRecipes;
          if(RestaurantMetricUnits.select){
            newSubRecipes = JSON.parse(JSON.stringify(subRecipes)).map((subrecipe) =>{
              if(subrecipe.unit === 'pound'){
                return !subrecipe.uniqueId ? { ...subrecipe, unit: 'kilo', uniqueId: uuidv4() } : { ...subrecipe, unit: 'kilo' }
              }
              return !subrecipe.uniqueId ? { ...subrecipe, uniqueId: uuidv4() } : { ...subrecipe }
            })
          }else{

            newSubRecipes = JSON.parse(JSON.stringify(subRecipes)).map((subrecipe) =>{
              if(subrecipe.unit === 'kilo'){
                return !subrecipe.uniqueId ? { ...subrecipe, unit: 'pound', uniqueId: uuidv4() } : { ...subrecipe, unit: 'pound' }
              }
              return !subrecipe.uniqueId ? { ...subrecipe, uniqueId: uuidv4() } : { ...subrecipe }
              })
            }

          //  // update ingredientOptions dropdown for recipe and sub-recipes
          //  dispatch(addSubRecipeIngredientsOptions({ ingredients: ingredientWithNetPrice }));
          //  dispatch(addRecipeIngredientOptions({ subRecipes: newSubRecipes, ingredients: ingredientWithNetPrice }));

          const valuesWithIngredientsCost = calculateSubRecipeIngredientCost({ subRecipes: JSON.parse(JSON.stringify(newSubRecipes)) }, subRecipeIngedientsPriceList);

          // calculate subrecipes varible cost
          const valuesWithVarialbesCost = calculateSubRecipeVariableCost(valuesWithIngredientsCost, expenses, generalData);

          const isAdd = await addSubRecipes(uid, valuesWithVarialbesCost);

          if (isAdd) {
            // dispatch(addSubReceipes(JSON.parse(JSON.stringify({ ...valuesWithVarialbesCost }))));

            // update recipes data
            // calculate recipes ingrdients cost

            // generete ingredients price list for recipes

            // generateIngredientPriceListForSubrecipes

            const recipePriceList = [...generateIngredientPriceListForSubrecipes(ingredientWithNetPrice), ...generateIngredientListForRecipes(newSubRecipes)];

            // // update ingredients price list for recipe in redux store
            // dispatch(updateRecipeIngredientList(recipePriceList));


            const valuesWithIngredientsCost = calculateRecipeIngredientCost({ recipes: JSON.parse(JSON.stringify(recipes)) }, recipePriceList);

            // calculate recipes varible cost
            const recipeValuesWithVarialbesCost = calculateRecipeVariableCost(valuesWithIngredientsCost, expenses, generalData);

            // calculate recipes margin and profit cost
            const valuesWithProfiteAndMargin = calculateRecipeProfitMargin(recipeValuesWithVarialbesCost, taxRate);

            const isAdd = await addRecipes(uid, valuesWithProfiteAndMargin);

            if (isAdd) {
              // Add all the data to the redux-store
                dispatch(addIngredients({ ingredients: ingredientWithNetPrice }));

                       // update ingredients price list for recipe in redux store
                dispatch(updateSubRecipeIngredientsList(subRecipeIngedientsPriceList));
    
                dispatch(addSubReceipes(JSON.parse(JSON.stringify({ ...valuesWithVarialbesCost }))));
                
                  // update ingredientOptions dropdown for recipe and sub-recipes
                dispatch(addSubRecipeIngredientsOptions({ ingredients: ingredientWithNetPrice }));
                dispatch(addRecipeIngredientOptions({ subRecipes: newSubRecipes, ingredients: ingredientWithNetPrice }));

                // update ingredients price list for recipe in redux store
                dispatch(updateRecipeIngredientList(recipePriceList));

                
                dispatch(addReceipes(JSON.parse(JSON.stringify({ ...valuesWithProfiteAndMargin }))));
            }


          }

        }
      } else {
        toast.error('You must login to make an entry');
      }
    }
    catch (error) {
      // console.log(error, 'error');
    }
  };

  // Add another row in the table
  const handleAddField = () => {
    prepend({"category": "", "ingredient": "", "waste_rate": 0, "unit": "", "price": 0});
  };


  // Function to remove row
  const handleRemoveTableRow = (index) => {
    // delete table row 
    rowRemove(index);

    // submit form and update table 
    handleSubmit(onSubmit)();
  };

  // Add new ingredient category
  const handleAddCategory = async (event, new_category) => {
    event.stopPropagation();
    await updateCategories(uid, { ingredientCategories: [...categories, { label: new_category, value: new_category }] });
    dispatch(addIngredientsCategories([...categories, { label: new_category, value: new_category }]));
  };


 const handleFilter = useCallback(() => {

  if(selectCategory || selectIngredient){

    let filteredIngredients = []
    let restData = []

    if(selectCategory && selectIngredient){
      // Searched data if selectCategory and select ingredient both are selected
        filteredIngredients = ingredients.filter((ingredient) =>{
          return(ingredient.ingredient === selectIngredient &&  ingredient.category === selectCategory) ? true : restData.push(ingredient) && false })
      } else {
        if(selectIngredient){
            // Searched data if ingredient is select only
          filteredIngredients = ingredients.filter((ingredient) =>{
            return ingredient.ingredient === selectIngredient ? true : restData.push(ingredient) && false })

        }else{
            // Searched data if category is select only
            filteredIngredients = ingredients.filter((ingredient) =>{
              return ingredient.category === selectCategory ? true : restData.push(ingredient) && false })
        }

      }
      
      reset({ ingredients :  filteredIngredients});  // reset form values after applying
      // setValue('ingredients', filteredIngredients)
      ingredientDispatch({ type: INGREDIENTS_ACTION_TYPE.CURRENT_INGREDIENT, payload: filteredIngredients });
      ingredientDispatch({ type: INGREDIENTS_ACTION_TYPE.REST_INGREDIENT, payload: restData });
      ingredientDispatch({ type: INGREDIENTS_ACTION_TYPE.TOTAL_INGREDIENT, payload: ingredients });

  }else{

        reset({ ingredients });  // reset form values after changing ingredients data
      // setValue('ingredients', ingredients)
      ingredientDispatch({ type: INGREDIENTS_ACTION_TYPE.CURRENT_INGREDIENT, payload: ingredients });
      ingredientDispatch({ type: INGREDIENTS_ACTION_TYPE.REST_INGREDIENT, payload: [] });
      ingredientDispatch({ type: INGREDIENTS_ACTION_TYPE.TOTAL_INGREDIENT, payload: ingredients });

  }

  }, [selectCategory, selectIngredient, ingredients, reset]);

  useEffect(() => {
    handleFilter();
  }, [ingredients, handleFilter]);


    // function for updating ingredient units after toggling ingredient units by user
  const updateDataUnit = async() =>{
   
   if(RestaurantMetricUnits.select){
    // update ingredients unit
    const newIngredientsArr = ingredients.map((ingredient) =>{
      return ingredient.unit === 'pound' ? {...ingredient, unit: 'kilo', price: (ingredient.price) * 2.20 } : { ...ingredient }
    })
    
     reset({ ingredients :  newIngredientsArr});  // reset form values after changing units
     // submit form and update ingredients, sub-recipes and recipes table 
      handleSubmit(onSubmit)();
  
    }else{

      const newIngredientsArr = ingredients.map((ingredient) =>{
        return ingredient.unit === 'kilo' ? { ...ingredient, unit: 'pound', price: (ingredient.price)/2.20 } : { ...ingredient }
      })

      reset({ ingredients :  newIngredientsArr});  // reset form values after changing units
      // // submit form and update ingredients, sub-recipes and recipes table 
      handleSubmit(onSubmit)();
    }
  }
      
  useEffect(() =>{
    if(signedIn){
      // update ingredient units after toggling ingredient units by user
      updateDataUnit()
    }
  }, [RestaurantImperialUnits.select, RestaurantMetricUnits.units])


  // Category Options with Delete button
  const SelectMenuButton = (props) => {

    // function for delete ingredients categories
    const handleDeleteCategory = async (event, deleted_category) => {
      if (signedIn) {
        event.stopPropagation();
        const new_categories = categories.filter((category) => (category.label !== deleted_category));
        await updateCategories(uid, { ingredientCategories: [...new_categories] });
        dispatch(addIngredientsCategories([...new_categories]));
      } else {
        toast.error('You must login to delete any category');
      }

    };


    return (
      <components.Option  {...props}>
        <div className="flex" style={{ justifyContent: "space-between" }}>
          <div>{props.children}</div>
          <button type='button' className='remove-btn' onClick={(event) => handleDeleteCategory(event, props.children)}><RxCross2 /></button>
        </div>
      </components.Option >
    );
  };

  return (
    <div>
      <div className='table_container'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <table className='ingredient_table'>
            <thead>
              <tr>
                <th>
                  <div className="filter_item flex">
                    <span>Ingredient</span>
                    <FilterDropdown options={generateIngredientsSearchOptions(ingredients)} selectValue={setSelectIngredient} value={selectIngredient} />
                  </div>
                </th>
                <th>
                  <div className="filter_item flex">
                    <span>Category</span>
                    <FilterDropdown options={categories} selectValue={setSelectedCategory} value={selectCategory} />
                  </div>
                </th>
                <th>Unit</th>
                <th>Purchasing Price</th>
                <th>Waste Rate</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr className='add_row'>
                <td colSpan="5">
                  <button type='button' className='add_btn' onClick={handleAddField}>
                    + Add
                  </button>
                </td>
                <td></td>
              </tr>

              {fields.map((field, index) => {
               
                return <tr key={field.id}>
                  <td>
                    <input type="text" {...register(`ingredients.${index}.ingredient`)} onBlur={handleSubmit(onSubmit)} placeholder="Enter Ingredient"
                    />

                  </td>


                  <td className='category_cell'>

                    <Controller
                      control={control}
                      name={`ingredients.${index}.category`}
                      render={({ field: { onChange, value, name, ref } }) => {
                        return <Select value={categories.find(c => c.value === value)} onChange={val => { onChange(val?.value); handleSubmit(onSubmit); }} className="select" classNamePrefix="select" isDisabled={false} isClearable={true} isSearchable={true} components={{ Option: SelectMenuButton }} options={categories} onBlur={handleSubmit(onSubmit)} placeholder="Select Category"
                          noOptionsMessage={({ inputValue }) => inputValue ? (
                            <div>
                              <span>{inputValue}</span>
                              <button type='button' className='add_btn' onClick={(event) => {
                                dispatch(addIngredientsCategories([...categories, { label: inputValue, value: inputValue }]));
                                handleAddCategory(event, inputValue);
                              }}>
                                + Add
                              </button>
                            </div>
                          ) : "No results found"} />;
                      }} />
                  </td>

                  <td>
                    <Controller
                      control={control}
                      name={`ingredients.${index}.unit`}
                      render={({ field: { onChange, value, name, ref } }) => {
                        let unitOptions = RestaurantMetricUnits.select ? RestaurantMetricUnits.unitOptions : RestaurantImperialUnits.unitOptions
                        return <Select value={unitOptions.find(c => c?.value === value)}
                          onChange={val => { onChange(val?.value); handleSubmit(onSubmit); }} className="select" classNamePrefix="select" isDisabled={false} isClearable={true} isSearchable={true} options={unitOptions} onBlur={handleSubmit(onSubmit)} placeholder="Select Unit" />;
                      }} />
                  </td>

                  <td>
                    <div className="table_field">
                      <span>$</span>
                      <Cleave
                        options={{
                          numeral: true,
                          numeralThousandsGroupStyle: 'thousand'
                        }}
                        onChange={(e) => {
                          // parse the value to a number and set it to the state
                          const value = parseFloat(e.target.value.replace(/,/g, ''));
                          if (!isNaN(value) || e.target.value === '') {
                            setValue(`ingredients.${index}.price`, value);
                          }
                        }}
                        onBlur={handleSubmit(onSubmit)}
                        placeholder={"Enter value"}
                        value={field.price || ''}
                      />
                    </div>
                  </td>

                  <td>
                    <div className='table_field' style={{ gap: "0" }}>
                      <input type="number" {...register(`ingredients.${index}.waste_rate`)} onBlur={handleSubmit(onSubmit)} placeholder={"Enter value"} style={{ width: "26%" }} />
                      <span>%</span>
                    </div>
                  </td>

                  <td>
                    <div className="table_btns">
                      <button type='button' title='Delete'>
                        <RiDeleteBin5Line onClick={() => handleRemoveTableRow(index)} />
                      </button>
                    </div>
                  </td>
                </tr>;
              })}

            </tbody>
          </table>
        </form>
      </div>
    </div>
  );
};

export default  memo(Ingredient);




