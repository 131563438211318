import React, { useRef } from 'react';
import { PiSortDescendingBold, PiSortAscendingBold } from 'react-icons/pi';
import useOutsideClose from '../hooks/useOutsideClose';

const SortingFilter = ({ setShowFilter, handleSorting }) => {

    const sortFilterRef = useRef();

    useOutsideClose(sortFilterRef, () => {
        setShowFilter(false);
    });

    return (
        <div className='filter_options flex chip' ref={sortFilterRef}>
            <button type='button' className='flex' onClick={() => handleSorting(1)}>
                <PiSortDescendingBold />
                <span>Asc</span>
            </button>

            <button type='button' className='flex' onClick={() => handleSorting(-1)}>
                <PiSortAscendingBold />
                <span>Desc</span>
            </button>
        </div>
    );
};

export default SortingFilter;
