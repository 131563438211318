import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { generalDataSchema, numberValidationSchema } from '../utils/formSchemas';
import { addGeneralData } from '../firebase/firestore/restaurant-general';
import TableField from './TableField';
import { addGeneralData as addGeneralDataToSlice } from '../state/features/generalDataSlice';
import { Controller } from 'react-hook-form';
import { addRecipes, addSubRecipes } from '../firebase/firestore/recipes';
import { addReceipes } from '../state/features/recipesSlice';
import { calculateRecipeProfitMargin, calculateRecipeVariableCost, calculateSubRecipeVariableCost } from '../utils/calculations';
import { addSubReceipes } from '../state/features/subRecipeSlice';

// Day open weekyly
const daysOpenWeeklyOptions = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
  { value: 7, label: 7 },
];

const RestaurantGeneralData = () => {
  const dispatch = useDispatch();
  const initialValue = { productSoldDaily: 0, totalEmployees: 0, daysWeeklyOpen: 0 };

  const { data: generalData } = useSelector((state) => state.generalData);
  const { data: { uid } } = useSelector((state) => state.authSlice);
  const { data: expenses } = useSelector(state => state.expensesSlice);
  const { data: recipes } = useSelector((state) => state.recipes);
  const { data: subRecipes } = useSelector((state) => state.subRecipe);
  const { taxRate } = useSelector((state) => state.tax);

  const { values, handleChange, handleSubmit, errors, touched, setValues } = useFormik({
    initialValues: initialValue,
    validationSchema: generalDataSchema,
    onSubmit: async (values) => {
      const isAdded = await addGeneralData(uid, values);
      if (isAdded) {
        // console.log()
        dispatch(addGeneralDataToSlice({ ...values }));

        // update subrecipes cost after change the expenses 
        const subValuesWithVarialbesCost = calculateSubRecipeVariableCost({ recipes: subRecipes }, expenses, { ...values });

        const isSubRecipeUpdate = await addSubRecipes(uid, subValuesWithVarialbesCost);

        if (isSubRecipeUpdate) {
          dispatch(addSubReceipes(JSON.parse(JSON.stringify({ ...subValuesWithVarialbesCost }))));
        }

        // update recipes cost after change the general data
        const valuesWithVarialbesCost = calculateRecipeVariableCost({ recipes }, expenses, { ...values });
        const valuesWithProfiteAndMargin = calculateRecipeProfitMargin(valuesWithVarialbesCost, taxRate);
        const isAdded = await addRecipes(uid, valuesWithProfiteAndMargin);

        if (isAdded) {
          dispatch(addReceipes(JSON.parse(JSON.stringify({ ...valuesWithProfiteAndMargin }))));
        }

      }
    }
  });


  useEffect(() => {
    setValues(generalData);
  }, [generalData]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className='table_container'>
          <table className='rest_gen_table'>
            <tbody>
              <tr>
                <td>Products Sold Daily (aprox.)</td>
                <td>
                  <TableField
                    name='productSoldDaily'
                    value={values.productSoldDaily}
                    onChange={handleChange}
                    onBlur={handleSubmit}
                    placeholder={"Enter value"}

                  />
                  {/* <input type='number' name='productSoldDaily' value={values.productSoldDaily} placeholder='Enter values' onChange={handleChange} onBlur={handleSubmit} /> */}
                  {touched.productSoldDaily && errors.productSoldDaily && (
                    <span className='helper_text'>{errors.productSoldDaily}</span>
                  )}
                </td>
              </tr>

              <tr>
                <td>Number of Employees</td>
                <td>
                  <TableField
                    name='totalEmployees'
                    value={values.totalEmployees}
                    onChange={handleChange}
                    onBlur={handleSubmit}
                    placeholder={"Enter value"}
                  />
                  {/* <input type='number' name='totalEmployees' value={values.totalEmployees} placeholder='Enter values' onChange={handleChange} onBlur={handleSubmit} /> */}
                  {touched.totalEmployees && errors.totalEmployees && (
                    <span className='helper_text'>{errors.totalEmployees}</span>
                  )}
                </td>
              </tr>

              <tr>
                <td>Days Open Weekly</td>


                <td className="days-select" style={{ position: 'relative' }}>

                  <Select name='daysWeeklyOpen' value={{ value: values.daysWeeklyOpen, label: values.daysWeeklyOpen }}
                    onChange={selectedOption => {
                      let event = { target: { name: `daysWeeklyOpen`, value: selectedOption.value } };
                      handleChange(event);
                    }} className="select" classNamePrefix="select" isDisabled={false} isClearable={true} isSearchable={true} options={daysOpenWeeklyOptions} onBlur={handleSubmit} />

                  {touched.daysWeeklyOpen && errors.daysWeeklyOpen && (<span className='helper_text'>{errors.totalEmployees}</span>)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
    </>
  );
};

export default RestaurantGeneralData;
