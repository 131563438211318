import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./features/authSlice";
import expensesSlice from "./features/expensesSlice";
import generalDataSlice from "./features/generalDataSlice";
import ingredientSlice from "./features/ingredientSlice";
import subRecipeSlice from "./features/subRecipeSlice";
import recipesSlice from "./features/recipesSlice";
import IngrdientListSlice from "./features/IngredientsPriceSlice";
import IngredientOptionsSlice from "./features/IngredientOptionsSlice";
import TaxSlice from "./features/TaxSlice";
import subscriptionSlice from "./features/subscriptionSlice";
import unitSlice from "./features/unitSlice";

export const store = configureStore({
  reducer: {
    authSlice,
    expensesSlice,
    generalData : generalDataSlice,
    ingredients : ingredientSlice,
    subRecipe: subRecipeSlice,
    recipes : recipesSlice,
    ingredientList : IngrdientListSlice,
    ingredientOptions : IngredientOptionsSlice,
    tax: TaxSlice,
    subscriptionInfo : subscriptionSlice,
    units: unitSlice
  },
});
