import React, { useState, useRef } from 'react';
import { MdLogout } from 'react-icons/md';
import { SignOutRestaurant, updateUserAuth } from '../firebase/firestore/auth';
import { useDispatch, useSelector } from 'react-redux';
import useOutsideClose from '../hooks/useOutsideClose';
import useScrollDisable from '../hooks/useScrollDisable';
import { toast } from 'react-hot-toast';
import ToggleSwitch from './ToggleSwitch';

import { addRestaurantUnitsInfo } from '../firebase/firestore/restaurant-general';
import { selectImperialUnits, selectMetricUnits } from '../state/features/unitSlice';
import { useNavigate } from 'react-router-dom';
import Subscription from './Subscription';

const UserDrawer = ({ isDrawerOpen, toggleDrawer }) => {
    const navigate = useNavigate()

    const dispatch = useDispatch();
    const { data: { email, uid } } = useSelector(state => state.authSlice);
    const { RestaurantMetricUnits, RestaurantImperialUnits } = useSelector(state => state.units);

    const [newPassword, setNewpassword] = useState('');

    const handleUnitCheck = async(value, name) => {
    if(uid){
        if(name === 'RestaurantMetricUnits'){
            // add unit info to firebase
            const isAdded = await addRestaurantUnitsInfo(uid, { RestaurantMetricUnits: { ...RestaurantMetricUnits, select: value }, RestaurantImperialUnits : { ...RestaurantImperialUnits, select: !value } })
            if(isAdded){
                if(value){
                    dispatch(selectMetricUnits())
                }else{
                    dispatch(selectImperialUnits())
                }
            }

        }else{

            const isAdded = addRestaurantUnitsInfo(uid, { RestaurantMetricUnits: { ...RestaurantMetricUnits, select: !value }, RestaurantImperialUnits : { ...RestaurantImperialUnits, select: value } })

            if(isAdded){
                if(value){
                    dispatch(selectImperialUnits())
                }else{
                    dispatch(selectMetricUnits())
                }
            }

            
        }
 
        navigate('/') // redierct to home page because every options will be on home page after changing the units
    }

    };


    const handleChangePassword = () => {
        if (newPassword) {
            updateUserAuth(newPassword);
        } else {
            toast.error('Please insert password');
        }
    };

    const drawerRef = useRef();

    useOutsideClose(drawerRef, () => {
        toggleDrawer(false);
    });

    useScrollDisable(isDrawerOpen);



    return (
        <aside id='drawer' className={isDrawerOpen ? 'open' : ''} >
            <div className='drawer_content' ref={drawerRef}>
                <div className='drawer_head'>
                    <div>
                        <div>
                            <img src='/images/chef_emoji.jpg' alt='user-img' />
                        </div>
                        <h3>Settings</h3>
                    </div>
                    <div>
                        <button onClick={() => {
                            dispatch(SignOutRestaurant())
                            toggleDrawer(false)
                            }}
                            className='btn outlined google_btn'>
                            <div className='flex'>
                                <MdLogout fontSize="large" />
                                <span>Log out</span>
                            </div>
                        </button>
                    </div>
                </div>

                <div className='drawer_body'>
                    <div>
                        <h4>Weight System for ingredients</h4>
                        <p>Use ingredients based on</p>
                        <div className='box'>
                            <div className='info_box'>
                                <ToggleSwitch name='RestaurantMetricUnits' checked={RestaurantMetricUnits.select} setChecked={handleUnitCheck} />
                                <p>Kilo (kg) - Grams (gr)</p>
                            </div>
                            <div className='info_box'>
                                <ToggleSwitch name='RestaurantImperialUnits' checked={RestaurantImperialUnits.select} setChecked={handleUnitCheck} />
                                <p>Pound (lb) - Ounces (oz)</p>
                            </div>

                        </div>
                    </div>

                    <div>
                        <h4>Account</h4>
                        <div className='box'>
                            <div className='info_box'>
                                <p>Full Name</p>
                                <p>{email.split('@')[0]}</p>
                            </div>
                            <div className='info_box'>
                                <p>Email</p>
                                <p>{email}</p>
                            </div>
                            <div className='info_box'>
                                <p>New Password</p>
                                <p><input type="password" placeholder='Enter New Password' value={newPassword} onChange={(e) => setNewpassword(e.target.value)} /></p>
                            </div>
                            <div>
                                <button type='button' className='btn' onClick={handleChangePassword}>Save</button>
                            </div>
                        </div >
                    </div >

                {/* Add subscription  */}
                 <Subscription/>
                </div >
            </div >
        </aside >
    );
};

export default UserDrawer;






