import { decimalValueTrimmer } from "."

export const calculateIngredientNetPrice = (ingredients) =>{
  // calculate ingradients net Price using puchasing price and waste_rate
  const data = ingredients.map((ingredient) => {
    const netPrice = decimalValueTrimmer(ingredient.price / (1 - (ingredient.waste_rate/100)))
    return {...ingredient, netPrice}
  })

  return data

}


// Caluclate Sub-Recipes ingredients cost for each recipe 
export const calculateSubRecipeIngredientCost = (subRecipesData, ingredients) =>{


  const recipes = subRecipesData.subRecipes

  const data = recipes.map((recipe) =>{

    recipe.totalCost = 0; // reinitialize totalcost with 0
    const ingredientsCost = { totalCost : 0 }


    recipe.ingredients.forEach((recipeingredient) => {
     
       ingredients.forEach((ingredient) =>{

         if(recipeingredient.ingredient && (ingredient.uniqueId === recipeingredient?.uniqueId)){

          // change name of sub-recipes ingredient if ingredient name is to changed 
          recipeingredient.ingredient = ingredient.ingredient

          // add ingredient unique Id to the sub-recipes ingredients it's important to sync ingredient with sub-recipes
          // recipeingredients.uniqueId = ingredient.uniqueId;
          
          // update sub-recipe Ingredients unit if any changed ingredients table unit after creating sub-recipe
            if(ingredient.unit === 'kilo'){ 

                if(recipeingredient.unit === 'oz'){

                  recipeingredient.weight = decimalValueTrimmer(recipeingredient.weight * 28.3495) // convert weigth oz to gr
                }
                
                recipeingredient.unit = 'gr'

            } else if (ingredient.unit === 'liter'){

              recipeingredient.unit = 'ml' 

            } else if (ingredient.unit === 'unitary'){

              recipeingredient.unit = 'unit' 

            } else if (ingredient.unit === 'pound'){

                      if(recipeingredient.unit === 'gr'){
                        recipeingredient.weight = decimalValueTrimmer(recipeingredient.weight / 28.3495)// convert price gr to oz
                      }

                      recipeingredient.unit = 'oz' 
            }

          // calculate pricing of ingredients
            if(recipeingredient.unit === 'gr' || recipeingredient.unit === 'ml'){
              
              ingredientsCost[recipeingredient.ingredient] = decimalValueTrimmer(((recipeingredient.weight)/1000) *  ingredient.price)
              
              ingredientsCost.totalCost =  decimalValueTrimmer(ingredientsCost.totalCost + ingredientsCost[recipeingredient.ingredient])
              recipe.totalCost += ingredientsCost.totalCost

            } else if(recipeingredient.unit === 'liter' || recipeingredient.unit === 'kilo'){

              ingredientsCost[recipeingredient.ingredient] = ingredient.price
              ingredientsCost.totalCost =  parseFloat((ingredientsCost.totalCost + ingredientsCost[recipeingredient.ingredient]).toFixed(2))
              recipe.totalCost += ingredientsCost.totalCost

            }else if(recipeingredient.unit === 'unit'){
              
              ingredientsCost[recipeingredient.ingredient] = ingredient.price * recipeingredient.weight
              ingredientsCost.totalCost =  parseFloat((ingredientsCost.totalCost + ingredientsCost[recipeingredient.ingredient]).toFixed(2))
              recipe.totalCost += ingredientsCost.totalCost

            } else if(recipeingredient.unit === 'oz'){
              ingredientsCost[recipeingredient.ingredient] = decimalValueTrimmer(((recipeingredient.weight)/16) *  ingredient.price)
              ingredientsCost.totalCost =  parseFloat((ingredientsCost.totalCost + ingredientsCost[recipeingredient.ingredient]).toFixed(2))
              recipe.totalCost += ingredientsCost.totalCost
  
            }

          }

        })
    }) 
    
      return { ...recipe,ingredientsCost }


  })

 
  return {recipes : data}

}


// Calculte recipes variable cost for each recipe
// formulas to calculate variable cost
//  labor cost - (((E4/J6)/(J7*4.25))/8)60 - (((payroll/no_employee)/(daysweekly * 4.25))/8)60
//  rent cost - (E5/(J7*4.25))/J5 - (rent/(daysweekly * 4.25))/productSoldDaily
//  water cost - E6/43800 - water/43800
// Electricity or energy - E7/43800 - electricity/43800
// other cost - (E9/(J7*4.25))/J5 - (otherCost / (daysweekly * 4.25))/productSoldDaily
export const calculateSubRecipeVariableCost = (recipesData, expenses, generalData) =>{
    const recipes = recipesData.recipes
    const data = recipes.map((recipe) =>{
    let variableCost = { }
    if(recipe.cookingTime || recipe.preparationTime){
      
      // calculate labor cost
      variableCost.labor = decimalValueTrimmer(((((expenses.payroll / generalData.totalEmployees)/(generalData.daysWeeklyOpen * 4.25))/8)/60) *  recipe?.preparationTime);

      // calculate rent cost
      variableCost.rent = decimalValueTrimmer((expenses.rent/(generalData.daysWeeklyOpen * 4.25))/generalData.productSoldDaily)
      // calculate water cost
      variableCost.water = decimalValueTrimmer((expenses.water / 43800) * recipe?.preparationTime);
      // calculate electricity cost
      variableCost.electricity = decimalValueTrimmer((expenses.electricity / 43800) * recipe?.cookingTime)
      // calculate gas bill    
      variableCost.gas = decimalValueTrimmer((expenses.gas / 43800) * recipe?.cookingTime)
      // calculate other costs    

      variableCost.other = decimalValueTrimmer((expenses?.otherCost / (generalData.daysWeeklyOpen * 4.25))/generalData.daysWeeklyOpen)
    
      // calculate total variable cost
      variableCost.totalCost = decimalValueTrimmer(variableCost.labor + variableCost.rent + variableCost.water + variableCost.electricity + variableCost.gas + variableCost.other)

      // calculate recipe total cost of each recipe using variable cost and ingrdient cost

      const recipeCost = decimalValueTrimmer(recipe?.ingredientsCost?.totalCost + variableCost.totalCost)
      return {...recipe, variableCost, totalCost: recipeCost}
    }

    return { ...recipe }
})


return { subRecipes : data}
}





// Caluclate recipes ingredients cost for each recipe
export const calculateRecipeIngredientCost = (recipesData, ingredients) =>{
 
  const recipes = recipesData.recipes

  const data = recipes.map((recipe, i) =>{
    recipe.totalCost = 0; // reinitialize totalcost with 0
    let ingredientsCost = { totalCost : 0 }

    recipe.ingredients.forEach((recipeingredient) => {
     
       ingredients.forEach((ingredient) =>{
         if( recipeingredient.ingredient && (ingredient.uniqueId === recipeingredient.uniqueId)){
           
           // change recipe's ingredient name if ingredient name has changed 
           recipeingredient.ingredient = ingredient.ingredient;
           
           // update recipe Ingredients unit if are any changed ingredients table unit after creating sub-recipe
           if(ingredient.unit === 'kilo'){ 
             
             if(recipeingredient.unit === 'oz'){
               recipeingredient.weight = decimalValueTrimmer(recipeingredient.weight * 28.3495) // convert price oz to gr
              }
              recipeingredient.unit = 'gr'
              
            } else if (ingredient.unit === 'liter'){
              
              recipeingredient.unit = 'ml' 
              
            } else if (ingredient.unit === 'unitary'){
              
              recipeingredient.unit = 'unit' 
            } else if (ingredient.unit === 'pound'){
              if(recipeingredient.unit === 'gr'){
                recipeingredient.weight = decimalValueTrimmer(recipeingredient.weight / 28.3495)// convert price gr to oz
              }
              recipeingredient.unit = 'oz' 
            }
            
            if(recipeingredient.unit === 'gr' || recipeingredient.unit === 'ml'){
             
              
              ingredientsCost[recipeingredient.ingredient] = decimalValueTrimmer((ingredient.price/1000) *  recipeingredient.weight)

              ingredientsCost.totalCost =  decimalValueTrimmer(ingredientsCost.totalCost + ingredientsCost[recipeingredient.ingredient])
              recipe.totalCost += ingredientsCost.totalCost

            } else if(recipeingredient.unit === 'liter' || recipeingredient.unit === 'kilo'){

              ingredientsCost[recipeingredient.ingredient] = ingredient.price
              ingredientsCost.totalCost =  parseFloat((ingredientsCost.totalCost + ingredientsCost[recipeingredient.ingredient]).toFixed(2))
              recipe.totalCost += ingredientsCost.totalCost
            }

            else if(recipeingredient.unit === 'unit'){
              
              ingredientsCost[recipeingredient.ingredient] = ingredient.price * recipeingredient.weight
              ingredientsCost.totalCost =  parseFloat((ingredientsCost.totalCost + ingredientsCost[recipeingredient.ingredient]).toFixed(2))
              recipe.totalCost += ingredientsCost.totalCost

            } else if (recipeingredient.unit === 'oz'){
           
              ingredientsCost[recipeingredient.ingredient] = decimalValueTrimmer(((ingredient.price)/16) *  recipeingredient.weight)
          
              ingredientsCost.totalCost =  parseFloat((ingredientsCost.totalCost + ingredientsCost[recipeingredient.ingredient]).toFixed(2))
              recipe.totalCost += ingredientsCost.totalCost
  
            }

          }

        })
    }) 
    return {...recipe,ingredientsCost}

  })

  return {recipes : data}

}

// Calculte sub-recipes variable cost for each recipe
// formulas to calculate variable cost
//  labor cost - (((E4/J6)/(J7*4.25))/8)60 - (((payroll/no_employee)/(daysweekly * 4.25))/8)60
//  rent cost - (E5/(J7*4.25))/J5 - (rent/(daysweekly * 4.25))/productSoldDaily
//  water cost - E6/43800 - water/43800
// Electricity or energy - E7/43800 - electricity/43800
// other cost - (E9/(J7*4.25))/J5 - (otherCost / (daysweekly * 4.25))/productSoldDaily
export const calculateRecipeVariableCost = (recipesData, expenses, generalData) =>{

  const recipes = recipesData.recipes
  const data = recipes.map((recipe) =>{

            if(recipe.cookingTime || recipe.preparationTime){

              let variableCost = { }
              // calculate labor cost
              variableCost.labor = decimalValueTrimmer(((((expenses.payroll / generalData.totalEmployees)/(generalData.daysWeeklyOpen * 4.25))/8)/60) * recipe.preparationTime);
      
              // calculate rent cost
              variableCost.rent = decimalValueTrimmer((expenses.rent/(generalData.daysWeeklyOpen * 4.25))/generalData.productSoldDaily)
              // calculate water cost
              variableCost.water = decimalValueTrimmer((expenses.water / 43800) * recipe.preparationTime);
              // calculate electricity cost
              variableCost.electricity = decimalValueTrimmer((expenses.electricity / 43800) * recipe.cookingTime)
              // calculate gas bill    
              variableCost.gas = decimalValueTrimmer((expenses.gas / 43800) * recipe.cookingTime)
              // calculate other costs    
              variableCost.other = decimalValueTrimmer((expenses.otherCost / (generalData.daysWeeklyOpen * 4.25))/generalData.productSoldDaily)
  
              // calculate total variable cost
              variableCost.totalCost = decimalValueTrimmer(variableCost.labor + variableCost.rent + variableCost.water + variableCost.electricity + variableCost.gas + variableCost.other)
              
              // calculate recipe total cost of each recipe using variable cost and ingrdient cost
              const recipeCost = decimalValueTrimmer(recipe.ingredientsCost.totalCost + variableCost.totalCost)
  
              return {...recipe, variableCost, totalCost: recipeCost}
            }

            return {...recipe}
            
      
  })
  
  return { recipes : data}
}

// calculate Margin and Profile of each recipe
export const calculateRecipeProfitMargin = (recipeData, tax=0) =>{
  const recipes = recipeData.recipes

  const data = recipes.map((recipe) =>{
        let profitDetails = {  }

        profitDetails.tax = tax;

        const grossProfit = decimalValueTrimmer(recipe.sellingPrice - recipe.totalCost)

        profitDetails.grossProfit = grossProfit
        profitDetails.sellingPrice = recipe.sellingPrice;
        profitDetails.totalCost = recipe.totalCost
      
        if(tax && tax > 0){
          const netProfit = decimalValueTrimmer(grossProfit - grossProfit * (tax/100))
          const margin = decimalValueTrimmer((netProfit/recipe.sellingPrice) * 100) // Corrected formula

          profitDetails.netProfit = netProfit
          profitDetails.margin = margin
          return {...recipe, margin, profit: netProfit, profitDetails}

        }else{

          const margin = decimalValueTrimmer((grossProfit/recipe.sellingPrice) * 100) // Corrected formula

          profitDetails.netProfit = grossProfit
          profitDetails.margin = margin
          
          return {...recipe, margin, profit: grossProfit, profitDetails}
        }

      })

  return { recipes: data }

}




// Fuctions for calculate ingredient list for recipes and sub-recipes 
// sub-recipes ingredients will be ingredients list
// recipes ingredients will be merge list of ingredient list and sub-recipes list because sub-recipe also a ingredient for recipe

export const generateIngredientPriceListForSubrecipes = (ingredients) =>(ingredients.map((ingredient) => ({ ingredient : ingredient.ingredient, price: ingredient.netPrice, unit: ingredient.unit, uniqueId: ingredient.uniqueId })))

export const generateIngredientListForRecipes = (subRecipes) =>(subRecipes.map((recipe) => ({ ingredient : recipe.subRecipe, price: (recipe.totalCost)/recipe.output, unit: recipe.unit, uniqueId: recipe?.uniqueId })))


// Fuctions for calculate ingredient select search optiopns for recipes and sub-recipes
export const generateIngredientOptionFromIngredients = (ingradients) =>(ingradients.map((ingredient) => ({ value: ingredient.ingredient,label:ingredient.ingredient, uniqueId: ingredient?.uniqueId})))

export const generateIngredientOptionFromSubRecipes = (subRecipes) =>(subRecipes.map((subRecipe) => ({ value: subRecipe.subRecipe,label:subRecipe.subRecipe, uniqueId:subRecipe?.uniqueId  })))

// Function for calculate and return ingredient Unit options
export const genereteIngredientUnitValue = (selectIngredient, ingredientList) => {

  let unitValue;
  ingredientList.forEach((ingredient) => {
    if(ingredient.ingredient === selectIngredient){

      if(ingredient.unit === 'kilo'){
        unitValue = 'gr'
      }else if(ingredient.unit === 'liter'){
        unitValue = 'ml'
      }else if(ingredient.unit === 'unitary'){
        unitValue = 'unit'
      } else if(ingredient.unit === 'pound'){
        unitValue = 'oz'
      }
    }
  })

  return unitValue
}


// Function for generate categories 
export const generateCategoriesOptions = (data) =>(Array.from(new Set(data.map((element) => element.category)))).map((category) =>( { value : category, label: category }))

