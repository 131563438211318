import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, getFirestore, setDoc, updateDoc} from "firebase/firestore";
import { getAuth, } from "firebase/auth";
import firebaseApp from "..";
import { toast } from "react-hot-toast";
import { addTax } from "../../state/features/TaxSlice";


// initialize auth
const auth = getAuth(firebaseApp);
// initialize db
const db = getFirestore(firebaseApp);


//  Add restaurant-general expensis
export const addGeneralExpenses = async(uid, values) =>{
  try {
    if(uid){
      await setDoc(doc(db, 'restaurant-expenses', uid), {...values })
      return true
    }
  } catch (error) {
    toast.error('Error!!! Semething went wrong')
  }
}

// Get restaurant-general expensis

export const getGeneralExpenses = async(uid) =>{
  try {
    if(uid){
      const docRef =  doc(db, 'restaurant-expenses', uid);
      const docSnap = await getDoc(docRef);
      if(docSnap.exists()){
        return docSnap.data()
      }
    }
  } catch (error) {
    toast.error('Error!!! Semething went wrong')
  }
}

// Add restaurant general data
export const addGeneralData = async(uid, values) =>{
    try {
      if(uid){
        await setDoc(doc(db, 'restaurant-general-data', uid), {...values})
        return true
      }
    } catch (error) {
     
      toast.error('Error!!! Semething went wrong')
    }
}

// Get generalData
export const getGeneralData = async(uid) =>{
    try {
      if(uid){
        const docRef = doc(db, 'restaurant-general-data', uid)
        const docSnap = await getDoc(docRef)
        if(docSnap.exists()){
          return docSnap.data()
        }
      }
    } catch (error) {
      
      toast.error('Error!!! Semething went wrong')
    }
}


// add ingredients in the restaurant-ingredients collection
export const addIngredients = async(uid, ingredients) =>{
  try {
    await setDoc(doc(db, 'restaurant-ingredients', uid), {...ingredients})
    return true
  } catch (error) {
    
    toast.error('Error!!! Something went wrong')
  }
}

// add ingredients in the restaurant-ingredients collection
export const getIngredientList = async(uid) =>{
  try {
    const docRef = doc(db, 'restaurant-ingredients', uid)
    const docSnap = await getDoc(docRef)
    if(docSnap.exists()){
      return docSnap.data()
    }
  } catch (error) {
    
    toast.error('Error!!! Something went wrong')
  }
}

// add tax in the restaurant-tax collection
export const addTaxRate = async(uid, values) =>{

    try {

        await setDoc(doc(db, 'restaurant-tax', uid), { ...values})
        return true

    } catch (error) {
      return Promise.reject({
        status : 400,
        message : 'Something went wrong'
       })
    }
  
}

// add tax in the restaurant-tax collection
export const getTaxRate = async(uid) =>{
    try {
      const docRef = doc(db, 'restaurant-tax', uid)
      const docSnap = await getDoc(docRef)

      if(docSnap.exists()){
        return docSnap.data()
      }
      
    } catch (error) {
      return Promise.reject({
        status : 400,
        message : 'Something went wrong'
       })
    }
  
}


// Get, Add, Update and Delete subscription info

// add subscription details in  restaurant-subscriptions firebase collection  
export const addSubscription = async(uid, values) =>{
  try {
    await setDoc(doc(db, 'restaurant-subscriptions', uid), {...values})
    return true

  } catch (error) {
   
    toast.error('Something went wrong!!!')
  }
}

// update subscription details in  restaurant-subscriptions firebase collection  
export const updateSubscription =  async(uid, values) =>{
  try {
      await updateDoc(doc(db, 'restaurant-subscriptions', uid), {...values})
      return true
  } catch (error) {
    return Promise.reject({
      status : 400,
      message : 'Something went wrong'
     })
  }
}

// Get subscription details from restaurant-subscriptions firebase collection
export const getSubscription = async(uid) =>{
  try {
    
    const docRef = doc(db, 'restaurant-subscriptions', uid)
    const docSnap = await getDoc(docRef)

    if(docSnap.exists()){
      return docSnap.data()
    }
    
  } catch (error) {
    return Promise.reject({
      status : 400,
      message : 'Something went wrong'
     })
  }

}

// Delete subscription details from restaurant-subscription firebase collection
export const deleteSubscription = async(uid) =>{
  try {
    await deleteDoc(doc(db, 'restaurant-subscriptions', uid))

    // show message
    toast.success('You subscription is expired. Please subscribed again.', {
      icon: 'ℹ️',
      style: {
        border: '1px solid #713200',
        padding: '16px',
        color: '#713200',
      },
      iconTheme: {
        primary: '#713200',
        secondary: '#FFFAEE',
      },
    });

    return true
    
  } catch (error) {
    return Promise.reject({
      status : 400,
      message : 'Something went wrong'
     })
  }
}


// AddRestaurantMetricUnits and RestaurantImperialUnits info in the collection

// add Restaurant unit info in  restaurant-units firebase collection  
export const addRestaurantUnitsInfo = async(uid, values) =>{
  try {
    await setDoc(doc(db, 'restaurant-units', uid), {...values})
    return true

  } catch (error) {
    toast.error('Something went wrong!!!')
  }
}

// add Restaurant unit info in  restaurant-units firebase collection  
export const getRestaurantUnitsInfo = async(uid) =>{
  try {
      const docRef = doc(db, 'restaurant-units', uid)
      const docSnap = await getDoc(docRef)

      if(docSnap.exists()){
        return docSnap.data()
      }

  } catch (error) {
    return Promise.reject({
      status : 400,
      message : 'Something went wrong'
     })
  }
}

