import { createSlice } from "@reduxjs/toolkit";
import { defaultIngrdients } from "../../data/generalData";
import { generateIngredientOptionFromIngredients, generateIngredientOptionFromSubRecipes } from "../../utils/calculations";


export const INGREDIENTOPTIONS_STATUS = Object.freeze({
    IDLE: 'idle',
    LOADING: 'loading',
    ERROR: 'error'
})


export const ingredientInitialList = {
  currentSubRecipes: [],
  subRecipesIngredientOptions : generateIngredientOptionFromIngredients(defaultIngrdients),
  recipesIngredientOptions : generateIngredientOptionFromIngredients(defaultIngrdients),
}

export const ingredientOptionsSlice = createSlice({
  name: 'ingredientOptions',
  initialState: ingredientInitialList,
  reducers: {
    addSubRecipeIngredientsOptions(state, action){
      const { ingredients } = action.payload;

      return  { ...state, subRecipesIngredientOptions: generateIngredientOptionFromIngredients(ingredients) }
    },

    addRecipeIngredientOptions(state, action){

      const { subRecipes, ingredients } = action.payload;

        return {...state, recipesIngredientOptions : [...generateIngredientOptionFromSubRecipes(subRecipes), ...generateIngredientOptionFromIngredients(ingredients) ]  }
     
    },

    addInitialIngredientsOptions(action, payload){

    }
  }
})


export const  { addSubRecipeIngredientsOptions, addRecipeIngredientOptions, addInitialIngredientsOptions } = ingredientOptionsSlice.actions;

export default ingredientOptionsSlice.reducer