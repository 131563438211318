// Importing required libraries
import React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import Select from 'react-select';
import { RxCross2 } from 'react-icons/rx';
import { genereteIngredientUnitValue } from '../utils/calculations';

//Child component for adding ingredients 
     const IngredientsFieldArray = ({ fieldName, index, isOpenRef, closeMenuOption, setCloseMenuOption, ingredientOptions, ingredientList, handleForm }) => {
      
      const { control, register, handleSubmit, onSubmit, setFocus, setValue } = handleForm;
      
      const { fields, prepend, remove } = useFieldArray({
          control,
          name: `${fieldName}[${index}].ingredients`,
      });

      const handleAddField = () => {
          // Open the dropdown by default
          isOpenRef.current = { isOpen: true, row: index };
          // Add new ingredient 
          prepend({ ingredient: '', weight: '', unit: '' });
      };

      // function for remove ingredients 
      const handleRemoveIngredient = (index) => {
          remove(index);
          // submit form after remove the ingredient
          handleSubmit(onSubmit)();
      };


      return (
          <tbody>
              <tr>
                  <td>
                      <button type="button" className='add_btn' onClick={handleAddField}>
                          + Add
                      </button>
                  </td>
              </tr>
              {fields.map((field, i) => (
                  <tr key={field.id}>
                      <td>
                          <Controller
                              control={control}
                              name={`${fieldName}.${index}.ingredients.${i}.ingredient`}
                              render={({ field: { onChange, value, name, ref } }) => {

                                  // get field value if ingredient unique id or value is to be matched
                                  const fieldValue = ingredientOptions.find(c =>(c?.value === value))

                                  if (isOpenRef.current?.isOpen && isOpenRef.current?.row === index && i === 0) {
                                      return <Select value={fieldValue}
                                          ref={(ref => ref?.focus())}
                                          onChange={val => {
                                              isOpenRef.current = {};

                                              onChange(val?.value);

                                              setValue(`${fieldName}.${index}.ingredients.${i}.uniqueId`, val?.uniqueId);

                                              setValue(`${fieldName}.${index}.ingredients.${i}.unit`, genereteIngredientUnitValue(val?.value, ingredientList));

                                              setFocus(`${fieldName}.${index}.ingredients.${i}.weight`);

                                          }} className="select" classNamePrefix="select" menuIsOpen={isOpenRef.current?.isOpen && isOpenRef.current?.row === index && i === 0} isDisabled={false} isClearable={true} isSearchable={true} options={ingredientOptions} onBlur={() => {
                                              isOpenRef.current = { isOpen: false, row: index };
                                              setCloseMenuOption(!closeMenuOption);
                                          }} placeholder="Enter Ingredient" />;
                                  }


                                  return (
                                      <Select
                                          value={fieldValue}
                                          onChange={val => {
                                              isOpenRef.current = false;
                                              onChange(val?.value);

                                              setValue(`${fieldName}.${index}.ingredients.${i}.uniqueId`, val?.uniqueId);
                                              // set amout name for focus for amout field
                                              setFocus(`${fieldName}.${index}.ingredients.${i}.weight`);
                                              
                                              setValue(`${fieldName}.${index}.ingredients.${i}.unit`, genereteIngredientUnitValue(val?.value, ingredientList));
                                          }}
                                          className="select" classNamePrefix="select" isDisabled={false} isClearable={true} isSearchable={true} options={ingredientOptions} placeholder="Enter Ingredient"
                                          styles={{
                                              singleValue: (provided, state) => {
                                                  return {
                                                      ...provided, fontSize: "0.75rem", color: "rgba(84, 88, 91, 0.8)", fontWeight: 300,
                                                  };
                                              },
                                          }}
                                      />
                                  );
                              }}
                          />
                      </td>

                      <td>
                          <input type='number' {...register(`${fieldName}.${index}.ingredients.${i}.weight`)} step='any' onBlur={handleSubmit(onSubmit)} placeholder='Qty' />
                      </td>

                      <td style={{ position: 'relative' }}>
                          <input type='text' disabled {...register(`${fieldName}.${index}.ingredients.${i}.unit`)} />
                      </td>

                      <td>
                          <button
                              type='button'
                              className="remove-btn"
                              title='Remove'
                              onClick={() => handleRemoveIngredient(i)}
                          >
                              <RxCross2 />
                          </button>
                      </td>
                  </tr>
              ))}

          </tbody>
      );
  };


  export default IngredientsFieldArray