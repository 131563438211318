import axios from "axios"
import { useCallback, useState } from "react"
import toast from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux";
import { addSubscription, getSubscription } from "../firebase/firestore/restaurant-general";
import { updateUser } from "../firebase/firestore/auth";
import { addSubscriptionDetails } from "../state/features/subscriptionSlice";

const useOneTimePayment = (Paddle, { setIsSubscribing }) => {
  const dispatch = useDispatch();
  const { data : auth, signedIn } = useSelector(state => state.authSlice);

  const [oneTimeProducts, setOneTimeProducts] = useState([])
  const [isFetchingOneTime, setIsFetchingOneTime] = useState(true);

  // GET ONE TIME PRODUCTS
  const getOneTimeProducts = useCallback(async(token) =>{
    try {
        const response = await axios.get(process.env.REACT_APP_BACKEND_URL + '/paddle/products', {
          headers : {
            'Authorization' : `Bearer ${token}`
          }
        });
        setOneTimeProducts(response.data.response.products);
    } catch (error) {
        toast.error(error.message)
    } finally {
        setIsFetchingOneTime(false)
    }
  }, [])

  // PURCHASE PRODUCTS
  const puchaseOneTimeProduct = async(productId) => {
    if(signedIn) {
      setIsSubscribing(true)  

      // GET CURRENT SUBSCRIPTION DETAILS
      const existsSubscription = await getSubscription(auth.uid);
      if(existsSubscription && existsSubscription.is_subscription){
        toast.error('Already subscribed user!')
        return
      }

      try {
          // Start the subscription checkout process
          Paddle.Checkout.open({
              product: productId, 
              email: auth.email, 
              passthrough: auth.uid,
              allowQuantity : false,
              successCallback: async (data) => {
                  const { checkout, product, user } = data;
                  const { prices } = checkout;

                    // UPDATE USER DETAILS
                    await updateUser(checkout.passthrough, { subscription_type: product.name, is_subscription : true })

                    // UPDATE OR ADD USER SUBSCRIPTION DETAILS
                    await addSubscription(auth.uid, {
                      product : product.id,
                      status : 'active',
                      passthrough : checkout.passthrough,
                      checkout_id : checkout.id,
                      currency : prices.vendor.currency,
                      country : user.country,
                      email : user.email,
                      is_subscription : true,
                      subscription_type : product.name,
                      date_of_subscription : new Date(checkout.created_at).toUTCString(),
                      next_date_of_subscription : null,
                      subscription_cancel_url : null
                    })

                    // ADD SUBSCRIPTION DETAILS TO REDUX STORE 
                    dispatch(addSubscriptionDetails({ is_subscription : true, status : 'active', subscription_type : product.name }))
                    toast.success('Subscribed successfully!')
              },
              closeCallback: (data) => {
                  //ADD YOUR EXTRA LOGIC TO SEND TO BACKEND
                  
                  toast.error('Subscription failed!')
              }
          });
      } catch (error) {
        // console.log(error.message)   
      } finally {
        setIsSubscribing(false)
      }
    }
  }


  return {
    oneTimeProducts, setOneTimeProducts, isFetchingOneTime, setIsFetchingOneTime, getOneTimeProducts, puchaseOneTimeProduct
  }
}

export default useOneTimePayment

