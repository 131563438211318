import { setDoc, getDoc, getFirestore, doc, updateDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import firebaseApp from "..";
// import { toast } from "react-hot-toast";

// initialize auth
const auth = getAuth(firebaseApp)
// initialize db 
const db = getFirestore(firebaseApp)

// add recipes in the recipes collections
export const addRecipes = async(uid, recipes) =>{
  try {
    await setDoc(doc(db, 'restaurant-recipes', uid), {...recipes})
    return true
  } catch (error) {
    return Promise.reject({
      status : 400,
      message : 'Something went wrong'
     })

  }
}

// Get all recipes
export const getRecipes = async(uid) =>{
  try {
    const docRef = doc(db, 'restaurant-recipes', uid);
    const docSnap = await getDoc(docRef);

    if(docSnap.exists()){
      return docSnap.data()
    }

  } catch (error) {
    return Promise.reject({
      status : 400,
      message : 'Something went wrong'
     })
  }

}

// add subrecipes in the database

export const addSubRecipes = async(uid, recipes) =>{
  try {
    await setDoc(doc(db, 'restaurant-sub-recipes', uid), {...recipes})
    return true
  } catch (error) {
      return Promise.reject({
      status : 400,
      message : 'Something went wrong'
     })
    // toast.error('Something went wrong!!!')
  }
}

// Get all sub-recipes
export const getSubRecipes = async(uid) =>{
  try {
    const docRef = doc(db, 'restaurant-sub-recipes', uid);
    const docSnap = await getDoc(docRef);

    if(docSnap.exists()){
      return docSnap.data()
    }

  } catch (error) {
    return Promise.reject({
      status : 400,
      message : 'Something went wrong'
     })
  }

}

// Add restaurant-categories
export const addCategories = async(uid, values) =>{
  try {
    await setDoc(doc(db, 'restaurant-categories', uid), { ...values })
    return true
  } catch (error) {
    return Promise.reject({
      status : 400,
      message : 'Something went wrong'
     })
  }
}


// Update restaurant-categories
export const updateCategories =  async(uid, values) =>{
  try {
      await updateDoc(doc(db, 'restaurant-categories', uid), {...values})
      return true
  } catch (error) {
    return Promise.reject({
      status : 400,
      message : 'Something went wrong'
     })
  }
}

// Update restaurant-categories
export const getCategories =  async(uid) =>{
  try {
      const docRef = doc(db, 'restaurant-categories', uid)
      const docSnap = await getDoc(docRef)
      if(docSnap.exists()){
        return docSnap.data()
      }
  } catch (error) {
    return Promise.reject({
      status : 400,
      message : 'Something went wrong'
     })
  }
}