// Ingredient InitialData 
export const defaultIngrdients = [
    {"category": "Vegetables", "ingredient": "Swiss Chard", "waste_rate": 40, "unit": "kilo", "price": 2.62},
    {"category": "Vegetables", "ingredient": "Garlic", "waste_rate": 23, "unit": "kilo", "price": 4.25},
    {"category": "Vegetables", "ingredient": "Leek", "waste_rate": 48, "unit": "kilo", "price": 0.50},
    {"category": "Vegetables", "ingredient": "Artichoke", "waste_rate": 52, "unit": "kilo", "price": 2.2},
    {"category": "Vegetables", "ingredient": "Celery", "waste_rate": 37, "unit": "kilo", "price": 2.4},
    {"category": "Vegetables", "ingredient": "Fresh Peas", "waste_rate": 55, "unit": "kilo", "price": 1.2},
    {"category": "Vegetables", "ingredient": "Sweet Potatoes", "waste_rate": 25, "unit": "kilo", "price": 5.2},
    {"category": "Vegetables", "ingredient": "Eggplant", "waste_rate": 13, "unit": "kilo", "price": 1.2},
    {"category": "Vegetables", "ingredient": "Watercress", "waste_rate": 49, "unit": "kilo", "price": 2.1},
    {"category": "Vegetables", "ingredient": "Broccoli", "waste_rate": 50, "unit": "kilo", "price": 2.9},
    {"category": "Vegetables", "ingredient": "Onion", "waste_rate": 15, "unit": "kilo", "price": 0.8},
    {"category": "Vegetables", "ingredient": "Spring Onion", "waste_rate": 59, "unit": "kilo", "price": 0},
    {"category": "Vegetables", "ingredient": "Green Beans", "waste_rate": 10, "unit": "kilo", "price": 0},
    {"category": "Vegetables", "ingredient": "Cauliflower", "waste_rate": 55, "unit": "kilo", "price": 2.9},
    {"category": "Vegetables", "ingredient": "Endive", "waste_rate": 15, "unit": "kilo", "price": 0},
    {"category": "Vegetables", "ingredient": "Spinach", "waste_rate": 23, "unit": "kilo", "price": 2.9},
    {"category": "Vegetables", "ingredient": "Lettuce", "waste_rate": 25, "unit": "kilo", "price": 2.8},
    {"category": "Vegetables", "ingredient": "Cassava", "waste_rate": 25, "unit": "kilo", "price": 1.5},
    {"category": "Vegetables", "ingredient": "Kale", "waste_rate": 16, "unit": "kilo", "price": 0},
    {"category": "Vegetables", "ingredient": "Turnip", "waste_rate": 12, "unit": "kilo", "price": 0},
    {"category": "Vegetables", "ingredient": "Potato", "waste_rate": 15, "unit": "kilo", "price": 2.5},
    {"category": "Vegetables", "ingredient": "Cucumber", "waste_rate": 30, "unit": "kilo", "price": 3.5},
    {"category": "Vegetables", "ingredient": "Bell pepper", "waste_rate": 26, "unit": "kilo", "price": 4.9},
    {"category": "Vegetables", "ingredient": "Fresh Bean", "waste_rate": 60, "unit": "kilo", "price": 3.5},
    {"category": "Vegetables", "ingredient": "Beetroot", "waste_rate": 12, "unit": "kilo", "price": 2.2},
    {"category": "Vegetables", "ingredient": "Radicchio", "waste_rate": 25, "unit": "kilo", "price": 3.02},
    {"category": "Vegetables", "ingredient": "Brussels Sprout", "waste_rate": 23, "unit": "kilo", "price": 2.2},
    {"category": "Vegetables", "ingredient": "Cabbage", "waste_rate": 35, "unit": "kilo", "price": 1.5},
    {"category": "Vegetables", "ingredient": "Salsify", "waste_rate": 24, "unit": "kilo", "price": 2.2},
    {"category": "Vegetables", "ingredient": "Tomato", "waste_rate": 5, "unit": "kilo", "price": 4.3},
    {"category": "Vegetables", "ingredient": "Carrot", "waste_rate": 37, "unit": "kilo", "price": 3.3},
    {"category": "Vegetables", "ingredient": "Zucchini", "waste_rate": 28, "unit": "kilo", "price": 2.39},
    {"category": "Vegetables", "ingredient": "Pumpkin", "waste_rate": 31, "unit": "kilo", "price": 1.5},
    {"category": "Fruits", "ingredient": "Pineapple", "waste_rate": 38, "unit": "kilo", "price": 1.3},
    {"category": "Fruits", "ingredient": "Banana", "waste_rate": 35, "unit": "kilo", "price": 1.4},
    {"category": "Fruits", "ingredient": "Cherry", "waste_rate": 8, "unit": "kilo", "price": 8},
    {"category": "Fruits", "ingredient": "Plum", "waste_rate": 5, "unit": "kilo", "price": 2.5},
    {"category": "Fruits", "ingredient": "Prune", "waste_rate": 19, "unit": "kilo", "price": 4.5},
    {"category": "Fruits", "ingredient": "Cherimoya", "waste_rate": 29, "unit": "kilo", "price": 3.99},
    {"category": "Fruits", "ingredient": "Dates", "waste_rate": 13, "unit": "kilo", "price": 6.4},
    {"category": "Fruits", "ingredient": "Apricot", "waste_rate": 14, "unit": "kilo", "price": 2.1},
    {"category": "Fruits", "ingredient": "Peach", "waste_rate": 19, "unit": "kilo", "price": 3.3},
    {"category": "Fruits", "ingredient": "Nectarine", "waste_rate": 9, "unit": "kilo", "price": 4.4},
    {"category": "Fruits", "ingredient": "Strawberry", "waste_rate": 4, "unit": "kilo", "price": 4.7},
    {"category": "Fruits", "ingredient": "Pomegranate", "waste_rate": 36, "unit": "kilo", "price": 3.67},
    {"category": "Fruits", "ingredient": "Fig", "waste_rate": 3, "unit": "kilo", "price": 0},
    {"category": "Fruits", "ingredient": "Cactus Fruit", "waste_rate": 56, "unit": "kilo", "price": 0},
    {"category": "Fruits", "ingredient": "Persimmon", "waste_rate": 21, "unit": "kilo", "price": 0},
    {"category": "Fruits", "ingredient": "Kiwi", "waste_rate": 20, "unit": "kilo", "price": 0},
    {"category": "Fruits", "ingredient": "Kumquat", "waste_rate": 5, "unit": "kilo", "price": 0},
    {"category": "Fruits", "ingredient": "Lime", "waste_rate": 24, "unit": "kilo", "price": 0},
    {"category": "Fruits", "ingredient": "Lemon", "waste_rate": 36, "unit": "kilo", "price": 0},
    {"category": "Fruits", "ingredient": "Mandarin", "waste_rate": 37, "unit": "kilo", "price": 0},
    {"category": "Fruits", "ingredient": "Apple", "waste_rate": 14, "unit": "kilo", "price": 0},
    {"category": "Fruits", "ingredient": "Melon", "waste_rate": 33, "unit": "kilo", "price": 0},
    {"category": "Fruits", "ingredient": "Quince", "waste_rate": 31, "unit": "kilo", "price": 0},
    {"category": "Fruits", "ingredient": "Blackberry", "waste_rate": 7, "unit": "kilo", "price": 0},
    {"category": "Fruits", "ingredient": "Orange", "waste_rate": 35, "unit": "kilo", "price": 0},
    {"category": "Fruits", "ingredient": "Loquat", "waste_rate": 24, "unit": "kilo", "price": 0},
    {"category": "Fruits", "ingredient": "Avocado", "waste_rate": 37, "unit": "kilo", "price": 0},
    {"category": "Fruits", "ingredient": "Papaya", "waste_rate": 32, "unit": "kilo", "price": 0},
    {"category": "Fruits", "ingredient": "Pear", "waste_rate": 26, "unit": "kilo", "price": 0},
    {"category": "Fruits", "ingredient": "Grapefruit", "waste_rate": 42, "unit": "kilo", "price": 0},
    {"category": "Fruits", "ingredient": "Watermelon", "waste_rate": 30, "unit": "kilo", "price": 0},
    {"category": "Fruits", "ingredient": "Grape", "waste_rate": 6, "unit": "kilo", "price": 0},
    {"category": "Meats", "ingredient": "Lamb (Shoulder)", "waste_rate": 20, "unit": "kilo", "price": 0},
    {"category": "Meats", "ingredient": "Lamb (Ribs)", "waste_rate": 24, "unit": "kilo", "price": 0},
    {"category": "Meats", "ingredient": "Turkey", "waste_rate": 39, "unit": "kilo", "price": 0},
    {"category": "Meats", "ingredient": "Chicken", "waste_rate": 47, "unit": "kilo", "price": 0},
    {"category": "Meats", "ingredient": "Hen", "waste_rate": 42, "unit": "kilo", "price": 0},
    {"category": "Meats", "ingredient": "Beef (Boiling Meat)", "waste_rate": 27, "unit": "kilo", "price": 0},
    {"category": "Meats", "ingredient": "Beef (Rib Roast)", "waste_rate": 21, "unit": "kilo", "price": 0},
    {"category": "Meats", "ingredient": "Beef (Tenderloin)", "waste_rate": 41, "unit": "kilo", "price": 0},
    {"category": "Meats", "ingredient": "Beef (Rump)", "waste_rate": 16, "unit": "kilo", "price": 0},
    {"category": "Meats", "ingredient": "Tongue", "waste_rate": 23, "unit": "kilo", "price": 0},
    {"category": "Seafoods", "ingredient": "Hake", "waste_rate": 55, "unit": "kilo", "price": 0},
    {"category": "Seafoods", "ingredient": "Mussel", "waste_rate": 45, "unit": "kilo", "price": 0},
    {"category": "Seafoods", "ingredient": "River Smelt", "waste_rate": 45, "unit": "kilo", "price": 0},
    {"category": "Seafoods", "ingredient": "Sea Smelt", "waste_rate": 50, "unit": "kilo", "price": 0},
    {"category": "Seafoods", "ingredient": "Perch", "waste_rate": 52, "unit": "kilo", "price": 0},
    {"category": "Seafoods", "ingredient": "Salmon (Whole)", "waste_rate": 35, "unit": "kilo", "price": 0},
    {"category": "Seafoods", "ingredient": "Sardine", "waste_rate": 18, "unit": "kilo", "price": 0},
    {"category": "Seafoods", "ingredient": "Canned Sardine", "waste_rate": 35, "unit": "kilo", "price": 0},
    {"category": "Seafoods", "ingredient": "Surubi", "waste_rate": 45, "unit": "kilo", "price": 0}
    ]