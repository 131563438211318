import { useState } from "react";
import Select from "react-select";
import { HiChevronDown } from 'react-icons/hi';

const selectStyles = {
    control: provided => ({
        ...provided,
        minWidth: 170,
        // margin: 8
    }),
    menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" })
};

export const FilterDropdown = ({ options, selectValue, value }) => {
    
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Dropdown
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            target={
                <button
                    type='button'
                    onClick={() => setIsOpen(prev => !prev)}
                    // isSelected={isOpen}
                >
                    <HiChevronDown />
                </button>
            }
        >
            <Select
                className="selectFilter"
                classNamePrefix="selectFilter"
                autoFocus
                backspaceRemovesValue={false}
                // components={{ DropdownIndicator: null, IndicatorSeparator: null }}
                // controlShouldRenderValue={false}
                hideSelectedOptions={false}
                isClearable={true}
                menuIsOpen
                onChange={newValue => {
                    selectValue(newValue?.value)
                    setIsOpen(false)
                }}
                options={options}
                placeholder="Search..."
                styles={selectStyles}
                tabSelectsValue={false}
                value={value ? { label: value, value:  value } : ''}
            />
        </Dropdown>
    );
};

// styled components

const Menu = props => {
    return (
        <div
            style={{
                backgroundColor: "white",
                borderRadius: 8,
                boxShadow: "0px 8px 8px rgba(0, 0, 0, 0.1)",
                marginTop: 8,
                position: "absolute",
                right: "-50px",
                zIndex: 2,
                overflow: "hidden",
                textAlign: "left"
            }}
            {...props}
        />
    );
};

const Blanket = props => (
    <div
        style={{
            bottom: 0,
            left: 0,
            top: 0,
            right: 0,
            position: "fixed",
            zIndex: 1
        }}
        {...props}
    />
);

const Dropdown = ({ children, isOpen, target, onClose }) => (
    <div style={{ position: "relative" }}>
        {target}
        {isOpen ? <Menu>{children}</Menu> : null}
        {isOpen ? <Blanket onClick={onClose} /> : null}
    </div>
);

// const Svg = p => (
//     <svg
//         width="24"
//         height="24"
//         viewBox="0 0 24 24"
//         focusable="false"
//         role="presentation"
//         {...p}
//     />
// );

// const DropdownIndicator = () => (
//     <div style={{ color: "#000", height: 24, width: 32 }}>
//         <Svg>
//             <path
//                 d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
//                 fill="currentColor"
//                 fillRule="evenodd"
//             />
//         </Svg>
//     </div>
// );
