import { doc, getDoc, getFirestore, setDoc, updateDoc} from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged, signInWithPopup, GoogleAuthProvider,  updatePassword,
} from "firebase/auth";
import firebaseApp, { googleProvider } from "..";
import { toast } from "react-hot-toast";
import { STATUS, addInitialAuthInfo, addRestuarantInfo, initialAuthState, restaurantStatus } from "../../state/features/authSlice";
import { addExpenses, addInitialGeneralExpenses, initialGeneralExpenses } from "../../state/features/expensesSlice";
import { getGeneralData, getGeneralExpenses, getIngredientList, getTaxRate, addIngredients as addIngredientsToFirebase, getSubscription, getRestaurantUnitsInfo, deleteSubscription } from "./restaurant-general";
import { addGeneralData, addInitalGeneralData, initialGeneralValues } from "../../state/features/generalDataSlice";
import { addIngredients, addIngredientsCategories } from "../../state/features/ingredientSlice";
import { addCategories, getCategories, getRecipes, getSubRecipes } from "./recipes";
import { addInitialSubRecipes, addSubReceipes, subRecipeInitialState } from "../../state/features/subRecipeSlice";
import { addInitialRecipes, addReceipes, addReceipesCategories, recipeInitialState } from "../../state/features/recipesSlice";
import { addTax, updateShowTax } from "../../state/features/TaxSlice";
import { defaultIngrdients } from "../../data/generalData";
import { addRecipeIngredientList, addSubRecipeIngredientsList } from "../../state/features/IngredientsPriceSlice";
import { addRecipeIngredientOptions, addSubRecipeIngredientsOptions } from "../../state/features/IngredientOptionsSlice";
import { calculateIngredientNetPrice, generateCategoriesOptions } from "../../utils/calculations";
import { addSubscriptionDetails } from "../../state/features/subscriptionSlice";
import { selectImperialUnits, selectMetricUnits } from "../../state/features/unitSlice";

// initialize auth
const auth = getAuth(firebaseApp);
// initialize db
const db = getFirestore(firebaseApp);

// Create restaurant with custom form credentials
export const restaurantRegisterByFields = async ( email, password, restaurantName) => {
  try {
    const userCredentials = await createUserWithEmailAndPassword( auth, email, password);
    if (userCredentials.user) {
      const { email, uid, metadata } = userCredentials.user;
      // Add restaurant in the database
     const response = await addRestaurant(email, uid, metadata.creationTime, restaurantName);

    //Add default ingredients to firebase collection 
     await addIngredientsToFirebase(uid, { ingredients : calculateIngredientNetPrice(defaultIngrdients) })
    // Add default ingredients categories to firebase collection
     await addCategories(uid, { ingredientCategories : generateCategoriesOptions(defaultIngrdients) })

     
     if(response){
      return userCredentials.user
     }
    }
  } catch (error) {
    if (error.code.includes("auth/email-already-in-use")) {
      toast.error("User already exists!!!");
    }
  }
};

// Signin with custorm form credentials
export const signinByFields = async (email, password) => {
  try {
    const userCredentials = await signInWithEmailAndPassword( auth, email, password );
    if (userCredentials.user) {
      return userCredentials.user;
    }
  } catch (error) {
    if (
      error.code === "auth/user-not-found" ||
      error.code === "auth/wrong-password"
    ) {
      toast.error("Invalid login");
      // window.location.href('/')
    }
  }
};

// Signin with google
export const signinWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    // Get user credential like accessToken
    GoogleAuthProvider.credentialFromResult(result); // get credentials 
    const { email, uid, metadata, displayName } = result.user;
    // Add restaurant in the database
    const response = await addRestaurant(email, uid, metadata.creationTime, "", displayName);

    // Check default Ingredients is present in the firebase or not
    const ingredients = await getIngredientList(uid)
    if(!ingredients || ingredients === undefined){
        //Add default ingredients to firebase collection if user would be signin in firsttime
        addIngredientsToFirebase(uid, { ingredients : calculateIngredientNetPrice(defaultIngrdients) })
        // Add default ingredients categories to firebase collection
        addCategories(uid, { ingredientCategories : generateCategoriesOptions(defaultIngrdients) })
    }

    if(response){
      return await getRestaurantInfo(uid)
     }
  } catch (error) {
    // console.log("Signin with google", error);
  }
};

// user signout 
export const SignOutRestaurant = () =>{
  return async(dispatch)=>{
    try {
       await signOut(auth)
       dispatch(addInitialRecipes(recipeInitialState))
       dispatch(addInitialSubRecipes(subRecipeInitialState))
       dispatch(addInitialAuthInfo(initialAuthState))
       dispatch(addInitialGeneralExpenses(initialGeneralExpenses))
       dispatch(addInitalGeneralData(initialGeneralValues))
       dispatch(restaurantStatus(STATUS.IDLE))
       toast.success('Signed out')
    } catch (error){
    }
  }
}

// check user authentication
export const checkUserAuth =  () => {
  return async(dispatch, getState) =>{
    
    try {
      dispatch(restaurantStatus(STATUS.LOADING))
        onAuthStateChanged(auth, async(user) => {
          if(user){
            // Get restaurant info from firebase restaurant-users collection 
            const infoPromise =  getRestaurantInfo(user.uid)
            // Get expensis
            const expensisPromise =  getGeneralExpenses(user.uid)
            // Get general data 
            const generalDataPromise = getGeneralData(user.uid)

            // get IngredientList 
            const ingredientPromise = getIngredientList(user.uid)

            
            // subRecipeList 
            const subRecipesPromise = getSubRecipes(user.uid)
            
            // subRecipeList 
            const recipesPromise = getRecipes(user.uid)
            
            // getTaxRate 
            const taxValuePromise = getTaxRate(user.uid);
            
            // get categories 
            const categoriesPromise = getCategories(user.uid)

            // Get subscription details
            const subscriptionPromise = getSubscription(user.uid) 

            // Get units details 
            const unitsPromise = getRestaurantUnitsInfo(user.uid)

            // Resolved all promises
            const [info, expensis, generalData, ingredients, subRecipes, recipes, taxValue, categories, subscriptionDetails, unitsInfo] = await Promise.all([infoPromise, expensisPromise, generalDataPromise, ingredientPromise, subRecipesPromise, recipesPromise, taxValuePromise,categoriesPromise, subscriptionPromise, unitsPromise])

            if(unitsInfo && unitsInfo !== undefined && Object.keys(unitsInfo).length){
                if(unitsInfo.RestaurantMetricUnits.select){
                  dispatch(selectMetricUnits())
                }else{
                    dispatch(selectImperialUnits())
                }
            }
            
            if(categories && categories !== undefined && Object.keys(categories).length){
              // Add ingredients categories to the redux-store
                dispatch(addIngredientsCategories(categories.ingredientCategories))
                // Add recipes categories to the redux-store
                if(categories.recipeCategories && categories.recipeCategories !== undefined){
                  dispatch(addReceipesCategories(categories.recipeCategories))
                }
            }

            if(subscriptionDetails && subscriptionDetails !== undefined && Object.keys(subscriptionDetails)){
             
              if(subscriptionDetails.cancellation_effective_date && (new Date() > new Date(Date.parse(subscriptionDetails.cancellation_effective_date)))){
                // delete subscription information if current date passed cancellation_effective_date 
                await deleteSubscription(user.uid)
              }else{
                // Add subscription details in the redux-store
                dispatch(addSubscriptionDetails(subscriptionDetails))
              }
            }
            
            if(taxValue && Object.keys(taxValue).length){
              dispatch(addTax(taxValue.taxRate))
              dispatch(updateShowTax(taxValue.showTax))
            }
           
            if(recipes && Object.keys(recipes).length){
              dispatch(addReceipes({...recipes}))
            }

            if(subRecipes && Object.keys(subRecipes).length){

                // add sub-recipes in redux-store 
                dispatch(addSubReceipes({...subRecipes}))

                // Add ingredients price lsit for recipes
                dispatch(addRecipeIngredientList({ ...subRecipes , ...ingredients }));
                // Add ingredientOptions drodown to redux store
                dispatch(addRecipeIngredientOptions({ ...subRecipes , ...ingredients })); 

            }else{
              
              if(!ingredients || ingredients === undefined || !Object.keys(ingredients).length){
                // add ingredients price for recipes 
                // if ingredients not found in firebase to set default ingredients in redux-store 
                 dispatch(addRecipeIngredientList({ subRecipes : [],  ingredients : calculateIngredientNetPrice(defaultIngrdients)  }));
                // Add ingredients drodown options to redux store
                  dispatch(addRecipeIngredientOptions({ subRecipes : [], ingredients : calculateIngredientNetPrice(defaultIngrdients) }));   

              }else{
                // add ingredients price for recipes
                 dispatch(addRecipeIngredientList({ subRecipes : [], ...ingredients }));
                // Add ingredients drodown options to redux store
                  dispatch(addRecipeIngredientOptions({ subRecipes : [], ...ingredients }));    
              }

            }

            if(ingredients && Object.keys(ingredients).length){
                // Add ingredients to the ingredient state in redux state 
                dispatch(addIngredients({...ingredients}))

               // update ingredientsList price for sub-recipes
                dispatch(addSubRecipeIngredientsList({ ...ingredients }));

                 // update ingredientOptions drodown for sub-recieps and add to redux store
                dispatch(addSubRecipeIngredientsOptions({ ...ingredients }));
    
            }

            if(generalData){
              dispatch(addGeneralData({...generalData}))
            }

            if(expensis){
              dispatch(addExpenses({...expensis}))
            }

            if(info){
              // Add restaurant info into redux store 
              dispatch(addRestuarantInfo({uid: info.uid, email: info.email, restaurantName: info.restaurantName, accessToken: user.accessToken, signedIn: true}))
              dispatch(restaurantStatus(STATUS.IDLE))
            }
          }else{
            let user = {
              uid: "0haRl0MJgJdX0LXeruvdOjwhfL62"
            }

             // Get expensis
             const expensisPromise =  getGeneralExpenses(user.uid)
             // Get general data 
             const generalDataPromise = getGeneralData(user.uid)
 
             // get IngredientList 
             const ingredientPromise = getIngredientList(user.uid)
 
             
             // subRecipeList 
             const subRecipesPromise = getSubRecipes(user.uid)
             
             // subRecipeList 
             const recipesPromise = getRecipes(user.uid)
             
             
             // get categories 
             const categoriesPromise = getCategories(user.uid) 

               // Get units details 
            const unitsPromise = getRestaurantUnitsInfo(user.uid)
 
             
             // Resolved all promises
             const [expensis, generalData, ingredients, subRecipes, recipes, categories, unitsInfo] = await Promise.all([expensisPromise, generalDataPromise, ingredientPromise, subRecipesPromise, recipesPromise,categoriesPromise, unitsPromise])

             if(unitsInfo && unitsInfo !== undefined && Object.keys(unitsInfo).length){
              if(unitsInfo.RestaurantMetricUnits.select){
                dispatch(selectMetricUnits())
              }else{
                  dispatch(selectImperialUnits())
              }
            }
             
             if(categories && categories !== undefined && Object.keys(categories).length){
               // Add ingredients categories to the redux-store
                 dispatch(addIngredientsCategories(categories.ingredientCategories))
                 // Add recipes categories to the redux-store
                 if(categories.recipeCategories && categories.recipeCategories !== undefined){
                   dispatch(addReceipesCategories(categories.recipeCategories))
                 }
             }

            
             if(recipes && Object.keys(recipes).length){
               dispatch(addReceipes({...recipes}))
             }
 
             if(subRecipes && Object.keys(subRecipes).length){
 
                 // add sub-recipes in redux-store 
                 dispatch(addSubReceipes({...subRecipes}))
 
                 // Add ingredients price lsit for recipes
                 dispatch(addRecipeIngredientList({ ...subRecipes , ...ingredients }));
                 // Add ingredientOptions drodown to redux store
                 dispatch(addRecipeIngredientOptions({ ...subRecipes , ...ingredients })); 
 
             }else{
               
               if(!ingredients || ingredients === undefined || !Object.keys(ingredients).length){
                 // add ingredients price for recipes 
                 // if ingredients not found in firebase to set default ingredients in redux-store 
                  dispatch(addRecipeIngredientList({ subRecipes : [],  ingredients : calculateIngredientNetPrice(defaultIngrdients)  }));
                 // Add ingredients drodown options to redux store
                   dispatch(addRecipeIngredientOptions({ subRecipes : [], ingredients : calculateIngredientNetPrice(defaultIngrdients) }));   
 
               }else{
                 // add ingredients price for recipes
                  dispatch(addRecipeIngredientList({ subRecipes : [], ...ingredients }));
                 // Add ingredients drodown options to redux store
                   dispatch(addRecipeIngredientOptions({ subRecipes : [], ...ingredients }));    
               }
 
             }
 
 
             if(ingredients && Object.keys(ingredients).length){
                 // Add ingredients to the ingredient state in redux state 
                 dispatch(addIngredients({...ingredients}))
 
                // update ingredientsList price for sub-recipes
                 dispatch(addSubRecipeIngredientsList({ ...ingredients }));
 
                  // update ingredientOptions drodown for sub-recieps and add to redux store
                 dispatch(addSubRecipeIngredientsOptions({ ...ingredients }));
     
             }
 
             if(generalData){
               dispatch(addGeneralData({...generalData}))
             }
 
             if(expensis){
               dispatch(addExpenses({...expensis}))
             }

            dispatch(restaurantStatus(STATUS.ERROR))
          }
      });
    } catch (error) {
      dispatch(restaurantStatus(STATUS.ERROR))
    }
  }
};

// GENERATE ID TOKEN 
export const getAccessIdToken = async() =>{
  try {
    const idToken = await auth.currentUser.getIdToken();
    return idToken
  } catch (error) {
    return Promise.reject({
      status : 400,
      message : 'Something went wrong!'
    })
  }
}

// Add users in db
export const addRestaurant = async ( email, uid, createdAt, restaurantName = "", displayName = "", is_subscription=false ) => {
  try {
    // Check restaurant-user already exists or not
    const getRef = doc(db, "restaurant-users", uid);
    const docSnap = await getDoc(getRef);

    // Add restaurant if not already exits
    if (!docSnap.exists()) {
      await setDoc(doc(db, "restaurant-users", uid), { email, uid, createdAt, restaurantName, displayName, is_subscription });
      return true
    }else{
      // return true for informing signin with google because restaurant info already exists so assuming restaurant added successfully
      return true
    }
  } catch (error) {
     return Promise.reject({
      status : 400,
      message : 'Something went wrong'
     })
  }
};

// Get restaurantInfo info from db

export async function getRestaurantInfo(uid) {
  try {
    const getRef = doc(db, "restaurant-users", uid);
    const docSnap = await getDoc(getRef);
    if (docSnap.exists) {
      return docSnap.data();
    }
  } catch (error) {
    return Promise.reject({
      status : 400,
      message : 'Something went wrong'
     })
  }
}

// update restaurant-users single field like number of recipes and sub-recipes
export const updateSingleFieldUser =  async(uid, fieldName, value) =>{
  try {
      await updateDoc(doc(db, 'restaurant-users', uid), {
        [fieldName] : value
      })
  } catch (error) {
    return Promise.reject({
      status : 400,
      message : 'Something went wrong'
     })
  }
}

// update restaurant-user multiple fields 
export const updateUser =  async(uid, values) =>{
  try {
      await updateDoc(doc(db, 'restaurant-users', uid), {...values})
      return true
  } catch (error) {
    return Promise.reject({
      status : 400,
      message : 'Something went wrong'
     })
  }
}


// update User credeintials
export const updateUserAuth = async(newPassword) =>{
  try {
    const user = auth.currentUser
    // const cred =  EmailAuthProvider.credential(user.email, 'Surya@123456')
    // await reauthenticateWithCredential(user, cred)
    await updatePassword(user, newPassword)
    return true
  } catch (error) {
    if(error.code === "auth/requires-recent-login"){
      toast.error('Please login again to change your password!')
    }else if(error.code === "auth/user-token-expired"){
      toast.error('Token is expired!. To change password please do login againg!')
    }
  }
}

