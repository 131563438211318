import React from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import { useFormik } from "formik";
// import { signupSchema } from "../../utils/formSchemas";
import { addInitialGeneralExpenses, initialGeneralExpenses } from "../../state/features/expensesSlice";
import { addInitalGeneralData, initialGeneralValues } from "../../state/features/generalDataSlice";
import { addInitialSubRecipes, subRecipeInitialState } from "../../state/features/subRecipeSlice";
import { STATUS, addRestuarantInfo, restaurantStatus } from "../../state/features/authSlice";
import { addInitialRecipes, recipeInitialState } from "../../state/features/recipesSlice";
import { addIngredients } from "../../state/features/ingredientSlice";
import {  restaurantRegisterByFields } from "../../firebase/firestore/auth";
import Loader from "../Loader";
import { calculateIngredientNetPrice } from "../../utils/calculations";
import { defaultIngrdients } from "../../data/generalData";

const Signup = ({ setIsDialogOpen }) => {
  const dispatch = useDispatch();

  // configure formik and form validation
  const { values, handleChange, handleSubmit, errors, isSubmitting } =
    useFormik({
      initialValues: { name: "", email: "", password: "" },
      // validationSchema: signupSchema,
      onSubmit: async (values, { resetForm }) => {
        // Submit form and add restaurant info in the firebase database
        try {
          const user = await restaurantRegisterByFields(
            values.email,
            values.password,
            values.name
          );
          if (user) {
            // add restaurant info
            dispatch(addRestuarantInfo({
                uid: user.uid,
                email: user.email,
                accessToken: user.accessToken,
                restaurantName: values.name,
                signedIn: true,
              })
            );


            // Set initial data after new registration
            dispatch(addInitialRecipes(recipeInitialState))
            dispatch(addInitialSubRecipes(subRecipeInitialState))
            dispatch(addInitialGeneralExpenses(initialGeneralExpenses))
            dispatch(addInitalGeneralData(initialGeneralValues))
            dispatch(addIngredients({  ingredients : [...calculateIngredientNetPrice(defaultIngrdients)]}))

            dispatch(restaurantStatus(STATUS.IDLE))
      

            localStorage.setItem("accessToken", user.accessToken);
            toast.success("Restered successfully!!!");
            resetForm();
            setIsDialogOpen(false);
          }
        } catch (error) {
          dispatch(restaurantStatus(STATUS.ERROR))

        }
      },
    });

  return (
    <form onSubmit={handleSubmit} className="formbox">
      <div>
        <label>Restaurant Name</label>
        <div className="input_box">
          <input type="text" placeholder="Restaurant Name" name="name" value={values.name} onChange={handleChange} />
        </div>
        <span className="helper_text">{errors.name}</span>
      </div>

      <div>
        <label>Email</label>
        <div className="input_box">
          <input type="email" placeholder="Email" name="email" value={values.email} onChange={handleChange} />
        </div>
        <span className="helper_text">{errors.email}</span>
      </div>

      <div>
        <label>Password</label>
        <div className="input_box">
          <input type="password" placeholder="Password" name="password" value={values.password} onChange={handleChange} />
        </div>
        <span className="helper_text">{errors.password}</span>
      </div>

      <div>
        <button type="submit" className="btn" disabled={isSubmitting} >
          {isSubmitting ? <Loader btnLoader={true} /> : "Sign Up"}
        </button>
      </div>
    </form>
  );
};

export default Signup;
