import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { MdContentCopy } from 'react-icons/md';
import { toast } from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';
import Select from 'react-select';
import { calculateIngredientNetPrice, calculateRecipeIngredientCost, calculateRecipeProfitMargin, calculateRecipeVariableCost, calculateSubRecipeIngredientCost, calculateSubRecipeVariableCost, generateIngredientPriceListForSubrecipes, generateIngredientListForRecipes } from '../utils/calculations';
import { addSubReceipes, subRecipeInitialState } from '../state/features/subRecipeSlice';
import { addRecipeIngredientOptions } from '../state/features/IngredientOptionsSlice';
import { updateRecipeIngredientList } from '../state/features/IngredientsPriceSlice';
import { addReceipes } from '../state/features/recipesSlice';
import { addRecipes, addSubRecipes } from '../firebase/firestore/recipes';
import { updateSingleFieldUser } from '../firebase/firestore/auth';
import IngredientsFieldArray from './IngredientsFieldArray';
import { FilterDropdown } from './FilterDropdown';
import { generateSubRecipesSearchOptions } from '../utils/generateData';
import { subRecipesSchema } from '../utils/formSchemas';

export const SUB_RECIPES_ACTION_TYPE = {
    CURRENT_SUB_RECIPES: 'CURRENT_SUB_RECIPES',
    REST_SUB_RECIPES: 'REST_SUB_RECIPES',
    TOTAL_SUB_RECIPES: 'TOTAL_SUB_RECIPES'
  };
  
  const initialSubRecipesDataFlow = {
    totalSubRecipes: [],
    currenSubRecipes: [],
    restSubRecipes: [],
  };
  
  const subRecipesReducer = (state, action) => {
    switch (action.type) {
      case "TOTAL_SUB_RECIPES":
        return { ...state, totalSubRecipes: action.payload };
  
      case "REST_SUB_RECIPES":
        return { ...state, restSubRecipes: action.payload };
  
      case "CURRENT_SUB_RECIPES":
        return { ...state, currenSubRecipes: action.payload };
  
      default:
        return state;
    }
  };


        


const SubRecipe = () => {
    const dispatch = useDispatch();
    const { data: subRecipes } = useSelector((state) => state.subRecipe);
    const { data: recipes } = useSelector((state) => state.recipes);
    const { data: { uid }, signedIn } = useSelector((state) => state.authSlice);
    const { data: ingredients } = useSelector((state) => state.ingredients);
    const { data: expenses, expensesIsEmpty } = useSelector((state) => state.expensesSlice);
    const { data: generalData, generalDataIsEmpty } = useSelector((state) => state.generalData);
    const { subRecipesIngredientList } = useSelector(state => state.ingredientList);
    const { subRecipesIngredientOptions } = useSelector((state) => state.ingredientOptions);
    const { taxRate } = useSelector((state) => state.tax);
    const isOpenRef = useRef({});
    const { data: { is_subscription } } = useSelector(state => state.subscriptionInfo);
    const { RestaurantMetricUnits, RestaurantImperialUnits } = useSelector(state => state.units);

    // local states data for performing filter
    const [subRecipesData, subRecipesDispatcher] = useReducer(subRecipesReducer, initialSubRecipesDataFlow);
    const [selectSubRecipe, setSelectSubRecipe] = useState('');

    const [closeMenuOption, setCloseMenuOption] = useState(false);

    const { register, handleSubmit, control, setValue, setFocus, reset } = useForm({
        defaultValues: { subRecipes: subRecipeInitialState.data },
        resolver: yupResolver(subRecipesSchema)
    });
    
    const { fields: tableFields, remove: rowRemove } = useFieldArray({
        control,
        name: 'subRecipes'
    });

    const addAnotherRow = () => {

        if (!is_subscription && subRecipes.length >= 1) {
            toast.error('You can not add another recipe with your current plan');
            return;
        }

        const newData = { subRecipe:  "", ingredients : [{ ingredient: '', weight: '', unit:'' }], output: 0, unit: '', 
                            preparationTime: 0, cookingTime: 0,  totalCost:0, createdAt : new Date().toUTCString() }

        // prepend(new_row);
        dispatch(addSubReceipes(JSON.parse(JSON.stringify({ subRecipes: [newData, ...subRecipes] }))));
    };


    // Copy row values means duplicate the row
    const copyRow = (value) =>{
        if (!is_subscription && subRecipes.length >= 1) {
            toast.error('You can not add another recipe with your current plan');
            return;
        }
        let indexOfCopyRow=0;
        for (let index = 0; index < subRecipes.length; index++) {
              if(new Date(value.createdAt).getTime() === new Date(subRecipes[index].createdAt).getTime()){
                indexOfCopyRow = index;
              }
        }

        const newSubRecipes = [...subRecipes];
        newSubRecipes.splice(indexOfCopyRow, 0, { ...value, uniqueId: uuidv4() });
        dispatch(addSubReceipes(JSON.parse(JSON.stringify({ subRecipes: [...newSubRecipes] }))));
    }

    const handleRemoveTableRow = (index) => {
        // delete table row function
        rowRemove(index);
       
        // submit form and update table 
        handleSubmit(onSubmit)();
    };

    console.log(subRecipes, 'subRecipes')
    const onSubmit = async (values) => {
        try {
            if (signedIn) {
                if (expensesIsEmpty || generalDataIsEmpty) {
                    toast.error("Please fill expenses and general data before to continue");
                }
    
                // Get all data from current display values and rest data, it is important for filtering. 
                const totalValues = {}
                totalValues.subRecipes = [...values.subRecipes, ...subRecipesData.restSubRecipes].map((subRecipe) =>{
                    return !subRecipe.uniqueId ? { ...subRecipe, uniqueId: uuidv4() } : { ...subRecipe }
                })
    
                // calculate subrecipes ingrdients cost
                const valuesWithIngredientsCost = calculateSubRecipeIngredientCost({ subRecipes: JSON.parse(JSON.stringify(totalValues.subRecipes)) }, subRecipesIngredientList);
    
                // calculate subrecipes varible cost
                const valuesWithVarialbesCost = calculateSubRecipeVariableCost(valuesWithIngredientsCost, expenses, generalData);
    
                const isAdd = await addSubRecipes(uid, valuesWithVarialbesCost);
    
                if (isAdd) {
                    updateSingleFieldUser(uid, 'no_sub-recipes', valuesWithVarialbesCost.subRecipes.length);
    
                    dispatch(addSubReceipes(JSON.parse(JSON.stringify({ ...valuesWithVarialbesCost }))));
    
                    // update recipes table after updating sub-recipes 
    
                    // generete ingredients price list for recipes
                    const recipePriceList = [...generateIngredientPriceListForSubrecipes(calculateIngredientNetPrice(ingredients)), ...generateIngredientListForRecipes(valuesWithVarialbesCost.subRecipes)];
    
    
                    // update ingredients price list for recipe in redux store
                    dispatch(updateRecipeIngredientList(recipePriceList));
    
                    // update ingredientOptions dropdown for recipe 
                    dispatch(addRecipeIngredientOptions({ ...valuesWithVarialbesCost, ingredients }));
    
                    // calculate recipe Ingredient cost with updated recipePrice list
                    const recipesValuesWithIngredientsCost = calculateRecipeIngredientCost({ recipes: JSON.parse(JSON.stringify(recipes)) }, recipePriceList);
    
    
                    // calculate recipes varible cost
                    const recipesValuesWithVarialbesCost = calculateRecipeVariableCost(recipesValuesWithIngredientsCost, expenses, generalData);
    
                    // calculate recipes margin and profit cost
                    const valuesWithProfiteAndMargin = calculateRecipeProfitMargin(recipesValuesWithVarialbesCost, taxRate);
    
                    // update recipes in firebase collection 
                    const isAdd = await addRecipes(uid, valuesWithProfiteAndMargin);
    
                    if (isAdd) {
                        updateSingleFieldUser(uid, 'no_recipes', valuesWithProfiteAndMargin.recipes.length);
                        // update recipes state in redux store
                        dispatch(addReceipes(JSON.parse(JSON.stringify({ ...valuesWithProfiteAndMargin }))));
                    }
                }
            } else {
                toast.error('You must login to make an entry');
            }

        } catch (error) {
            // console.log(error, 'error')
        }

    };


    // function for handle filter and table data
    const handleFilter = useCallback(() => {
        if(selectSubRecipe){

          let filteredData = []
          let restData = []
            // Searched data if sub-recipe is select for fitering
            filteredData = subRecipes.filter((recipe) =>recipe.subRecipe === selectSubRecipe ? true : restData.push(recipe) && false)
    
            reset({subRecipes : [...filteredData]})  // reset form values after applying filters
            subRecipesDispatcher({ type: SUB_RECIPES_ACTION_TYPE.CURRENT_SUB_RECIPES, payload: [...filteredData] });
            subRecipesDispatcher({ type: SUB_RECIPES_ACTION_TYPE.REST_SUB_RECIPES, payload: [...restData] });
            subRecipesDispatcher({ type: SUB_RECIPES_ACTION_TYPE.TOTAL_SUB_RECIPES, payload: subRecipes });
      
        }else{
            reset({subRecipes : [...subRecipes]})   // reset form values after changing sub-recipes data
            subRecipesDispatcher({ type: SUB_RECIPES_ACTION_TYPE.CURRENT_SUB_RECIPES, payload: subRecipes });
            subRecipesDispatcher({ type: SUB_RECIPES_ACTION_TYPE.REST_SUB_RECIPES, payload: [] });
            subRecipesDispatcher({ type: SUB_RECIPES_ACTION_TYPE.TOTAL_SUB_RECIPES, payload: subRecipes });
        }
      
        }, [selectSubRecipe, subRecipes, reset]);
      
        useEffect(() => {
            // handleFilter and setInitalData in the form
            handleFilter();
        }, [subRecipes, handleFilter]);
        


    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className='table_container'>
                <table className='subrecipe_table'>
                    <thead>
                        <tr>
                            <th>
                                <div className="filter_item flex">
                                    <span>Sub-Recipe</span>
                                    {/* options={generateIngredientsSearchOptions(ingredients)} selectValue={setSelectIngredient} value={selectIngredient} */}
                                    <FilterDropdown options={generateSubRecipesSearchOptions(subRecipes)} value={selectSubRecipe} selectValue={setSelectSubRecipe} />
                                </div>
                            </th>
                            <th style={{ textAlign: "center" }}>
                                Final <br />
                                Output
                            </th>
                            <th style={{ textAlign: "center" }}>Unit</th>
                            <th>Ingredients</th>
                            <th style={{ textAlign: "center" }}>
                                Preparation <br />
                                Time
                            </th>
                            <th style={{ textAlign: "center" }}>
                                Cooking <br />
                                Time
                            </th>
                            <th>Total Cost</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className='add_row'>
                            <td colSpan="6">
                                <button type='button' className='add_btn' onClick={addAnotherRow}>+ Add</button>
                            </td>
                            <td style={{ backgroundColor: "#f8f8fa" }}></td>
                            <td style={{ backgroundColor: "#f8f8fa" }}></td>
                        </tr>

                        {
                            tableFields.map((recipe, index) => {
                                return <tr key={recipe.id}>
                                    <td>
                                        <input type="text" {...register(`subRecipes.${index}.subRecipe`)} onBlur={handleSubmit(onSubmit)} placeholder='Enter Value' />
                                    </td>

                                    <td align='center'>
                                        <input type="number" {...register(`subRecipes.${index}.output`)} step='any' onBlur={handleSubmit(onSubmit)} />
                                    </td>

                                    <td align='center'>
                                        <Controller control={control} name={`subRecipes.${index}.unit`}
                                            render={({ field: { onChange, value, name, ref } }) => {
                                                let unitOptions = RestaurantMetricUnits.select ? RestaurantMetricUnits.unitOptions : RestaurantImperialUnits.unitOptions
                                                return <Select value={unitOptions.find(c => c?.value === value)}
                                                    onChange={val => { onChange(val?.value); handleSubmit(onSubmit); }} className="select" classNamePrefix="select" isDisabled={false} isClearable={true} isSearchable={true} options={unitOptions} onBlur={handleSubmit(onSubmit)} />;
                                            }} />
                                    </td>

                                    <td>
                                        <table className='ingredients_table'>
                                            <IngredientsFieldArray fieldName={`subRecipes`} index={index}  isOpenRef={isOpenRef}  handleForm={{ handleSubmit, onSubmit, control, register, setFocus, setValue }} closeMenuOption={closeMenuOption} setCloseMenuOption={setCloseMenuOption}  ingredientOptions={subRecipesIngredientOptions} ingredientList={subRecipesIngredientList}   />
                                        </table>
                                    </td>

                                    <td align="center">
                                        <input type="number" {...register(`subRecipes.${index}.preparationTime`)} step='any' onBlur={handleSubmit(onSubmit)} placeholder='Enter Value' />
                                    </td>

                                    <td align="center">
                                        <input type="number" {...register(`subRecipes.${index}.cookingTime`)} step='any' onBlur={handleSubmit(onSubmit)} placeholder='Enter Value' />
                                    </td>

                                    <td style={{ backgroundColor: "#f8f8fa" }}>
                                        <div>
                                            <div className='table_field'>
                                                <span>$</span>
                                                <span>{subRecipesData.currenSubRecipes[index]?.totalCost ? subRecipesData.currenSubRecipes[index]?.totalCost.toLocaleString() : '0'}</span>
                                            </div>
                                        </div>
                                    </td>

                                    <td style={{ backgroundColor: "#f8f8fa" }}>
                                        <div className="table_btns flex">
                                            <button title='Copy'>
                                                <MdContentCopy onClick={() =>copyRow(subRecipesData.currenSubRecipes[index])} />
                                            </button>
                                            <button title='Delete'>
                                                <RiDeleteBin5Line onClick={() => handleRemoveTableRow(index)} />
                                            </button>
                                        </div>
                                    </td>
                                </tr>;
                            })
                        }
                    </tbody>
                </table>
            </div>
        </form>
    );
};

export default SubRecipe

