import { createSlice } from "@reduxjs/toolkit";

export const SUBSCRIPTION_STATUS = Object.freeze({
  LOADING: 'loading',
  ERROR: 'error',
  IDLE: 'idle'
})

export const subscriptionInitialState = {
  data : {
    status : '',
    subscription_type: '',
    subscription_id : '',
    email: '',
    date_of_subscription: null,
    next_date_of_subscription : null,
    payment_method : '',
    customer_email : '',
    currency : '',
    is_subscription : false,
    order_id : '',
    receipt_url : null,
    subscription_cancel_url: null,
    subscription_update_method_url: null,
  },

  fetch_status : SUBSCRIPTION_STATUS.IDLE
}

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState: subscriptionInitialState,
  reducers: {
    addSubscriptionDetails(state, action){
      return { ...state, data: { ...state.data, ...action.payload }} 
    },

    fetchingSubscriptionDetails(state, action){
      return {...state, fetch_status: action.payload}
    }
  }
})


export const { addSubscriptionDetails, fetchingSubscriptionDetails } = subscriptionSlice.actions

export default subscriptionSlice.reducer