import { createSlice } from "@reduxjs/toolkit";

export const EXPENSES_STATUS = Object.freeze({
  IDLE: "idle",
  LOADING: "loading",
  ERROR: "error",
});

export const initialGeneralExpenses = {
  data: { payroll: "", rent: "", water: "", electricity: "", gas: "", other: [], totalCost: 0 },

  expensesIsEmpty: true,

  status: EXPENSES_STATUS.LOADING,
};

const expensesSlice = createSlice({
  name: "generalExpenses",
  initialState: initialGeneralExpenses,

  reducers: {
    addExpenses(state, action) {
      const { payload } = action;
      return { ...state, data: {...payload}, expensesIsEmpty: false };
    },
    expensesStatus(state, action) {
      const { payload } = action;
      return { ...state, ...payload };
    },

    addInitialGeneralExpenses(state, action) {
      return { ...action.payload };
    },
  },
});

export const { addExpenses, expensesStatus, addInitialGeneralExpenses } =
  expensesSlice.actions;

export default expensesSlice.reducer;
