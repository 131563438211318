import { useEffect } from "react";

const useScrollDisable = (elem) => {
  useEffect(() => {
    elem
      ? document.body.classList.add("disableScroll")
      : document.body.classList.remove("disableScroll");
  }, [elem]);
};

export default useScrollDisable;
