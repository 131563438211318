import { Toaster } from "react-hot-toast";
import Header from "./components/Header";
import PageRoutes from "./routes/PageRoutes";

const App = () => {
  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <Header />
      <PageRoutes />
    </>
  );
};

export default App;
