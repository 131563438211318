import React from 'react';
import { Route, Routes } from 'react-router-dom';
import General from '../components/General';
import Recipes from '../components/Recipes';
import useScrollRestore from '../hooks/useScrollRestore';

const PageRoutes = () => {

    useScrollRestore();

    return (
        <div className="container" style={{ marginTop: "32px", marginBottom: "48px" }}>
            <Routes>
                <Route path="/" element={<General />} />
                <Route path="/recipes" element={<Recipes />} />
            </Routes>
        </div>
    );
};

export default PageRoutes;
