

export function debouncedFuntion(fn, timeout = 1000) {
  let timer;
  const debounced = (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(this, args);
    }, timeout);
    console.log(timer, "timer");
  };

  return debounced;
}


export function calCulateTotalCostOfExpenses(values){
  let totalCost = 0
  let otherCost = 0;

  for (const value in values) {
    if(value === 'totalCost' || value === 'otherCost'){
      continue;
    }

    if(value === 'other'){
      values[value].forEach(element => {
        otherCost += element.cost
      });
    }
    else{
      totalCost += values[value]
    }
  }

  return {otherCost, totalCost: totalCost + otherCost}
}


export function decimalValueTrimmer(value){
  return parseFloat((value).toFixed(2))
}

// // generate options array of ingredients select box
// export const generateIngredientsOptions = (ingredients) =>{
//   const data = ingredients.map((ingredient) =>{
//     return { value: ingredient.ingredient, label: ingredient.ingredient }
//   })

//   // console.log(data, 'data')
//   return data
// }