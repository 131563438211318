import { createSlice } from "@reduxjs/toolkit";

export const SUBRECIPE_STATUS = {
  IDLE : 'idle',
  LOADING : 'loading',
  ERROR : 'error'
}

export const subRecipeInitialState = {
  data : [
  {
    subRecipe:  "",
    ingredients : [{
      ingredient: '', weight: '', unit:''
    }],
    output: 0,
    unit: '',
    preparationTime: 0,
    cookingTime: 0, 
    totalCost:0,
    createdAt : new Date().toUTCString()
  }]
}

export const SubRecipeSlice = createSlice({
  name: 'SubRecipe',
  initialState: subRecipeInitialState,
  reducers: {
    addSubReceipes(state, action){
      const { payload } = action
      return { ...state, data : payload.subRecipes }
    },

    addInitialSubRecipes(state, action){
      return { ...action.payload }
    }
  }
})

export const { addSubReceipes, addInitialSubRecipes } = SubRecipeSlice.actions

export default SubRecipeSlice.reducer



