import { createSlice } from "@reduxjs/toolkit";
import { defaultIngrdients } from "../../data/generalData";
import { calculateIngredientNetPrice, generateIngredientListForRecipes, generateIngredientPriceListForSubrecipes } from "../../utils/calculations";

export const INGREDIENTLIST_STATUS = Object.freeze({
    IDLE: 'idle',
    LOADING: 'loading',
    ERROR: 'error'
})

// get initial ingredient list with price
const ingredientListWithPrice = generateIngredientPriceListForSubrecipes(calculateIngredientNetPrice(defaultIngrdients))

export const ingredientInitialList = {

  subRecipesIngredientList : ingredientListWithPrice,
  recipesIngredientList : ingredientListWithPrice,

}

export const ingredientListSlice = createSlice({
  name: 'ingredientList',
  initialState: ingredientInitialList,
  reducers: {
    addSubRecipeIngredientsList(state, action){
      const { ingredients } = action.payload;
      return  { ...state, subRecipesIngredientList :  generateIngredientPriceListForSubrecipes(calculateIngredientNetPrice(ingredients))}
    },

    updateSubRecipeIngredientsList(state, action){
      return  { ...state, subRecipesIngredientList :  [...action.payload]}
    },

    addRecipeIngredientList(state, action){
      const { subRecipes, ingredients } = action.payload;

        return { ...state, recipesIngredientList: [ ...generateIngredientPriceListForSubrecipes(calculateIngredientNetPrice(ingredients)), ...generateIngredientListForRecipes(subRecipes) ] }
    },

    updateRecipeIngredientList(state, action){
        return { ...state, recipesIngredientList: [ ...action.payload ] }
    },

    addInitialIngredientsList(action, payload){

    }
  }
})


export const  { addInitialIngredientsList, addSubRecipeIngredientsList, updateSubRecipeIngredientsList, addRecipeIngredientList, updateRecipeIngredientList } = ingredientListSlice.actions;

export default ingredientListSlice.reducer