import React, { useEffect, useState } from 'react';
import RestaurantGeneralData from './RestaurantGeneralData';
import Ingredient from './Ingredient';
import SubRecipe from './SubRecipe';
import Expenses from './Expenses';
import ToggleSwitch from './ToggleSwitch';
import { useDispatch, useSelector } from 'react-redux';
import { TAX_STATUS, addTax, removeTax, updateShowTax, updateTaxStatus } from '../state/features/TaxSlice';
import { addTaxRate } from '../firebase/firestore/restaurant-general';
import { toast } from 'react-hot-toast';
import { addRecipes } from '../firebase/firestore/recipes';
import { calculateRecipeProfitMargin } from '../utils/calculations';
import { addReceipes } from '../state/features/recipesSlice';

const General = () => {

    const dispatch = useDispatch();
    const { taxRate, showTax } = useSelector((state) => state.tax);
    const { data: { uid } } = useSelector((state) => state.authSlice);
    const { data: recipes } = useSelector((state) => state.recipes);
    const { data : { is_subscription } } = useSelector(state => state.subscriptionInfo);

    const [tax, setTax] = useState(taxRate);
    const [taxError, setTaxError] = useState('');

    const handleSubmitTax = async () => {

        if (uid) {
            if (isNaN(tax) || tax === "") {
                setTaxError('Please enter a valid number');
            } else {
                try {
                    setTaxError('');
                    dispatch(updateTaxStatus(TAX_STATUS.LOADING));
                    const isAdd = await addTaxRate(uid, { taxRate: +tax, showTax });
                    if (isAdd) {
                        dispatch(addTax(+tax));
                        // update recipes calculation after taxRate change
                        const valuesWithProfiteAndMargin = calculateRecipeProfitMargin({ recipes }, +tax);
                        const res = await addRecipes(uid, valuesWithProfiteAndMargin);
                        if (res) {
                            dispatch(addReceipes(JSON.parse(JSON.stringify({ ...valuesWithProfiteAndMargin }))));
                            dispatch(updateTaxStatus(TAX_STATUS.IDLE));

                        }
                    }

                } catch (error) {
                    toast.error('Something went wrong!');
                    dispatch(updateTaxStatus(TAX_STATUS.ERROR));
                }

            }
        } else {
            toast.error('Please login to make an entry');
        }
    };

    const handleTaxCheck = async (value) => {

        if(!is_subscription){
            toast.error('You cannnot add tax in calculation with current plan')
            return
        }

        dispatch(updateShowTax(value));

        if (!value) {
            dispatch(updateTaxStatus(TAX_STATUS.LOADING));
            dispatch(removeTax());
            try {
                const isAdd = await addTaxRate(uid, { taxRate: 0, showTax: false });
                if (isAdd) {
                    dispatch(addTax(0));
                    // update recipes calculation after taxRate change
                    const valuesWithProfiteAndMargin = calculateRecipeProfitMargin({ recipes }, 0);
                    const res = await addRecipes(uid, valuesWithProfiteAndMargin);
                    if (res) {
                        dispatch(addReceipes(JSON.parse(JSON.stringify({ ...valuesWithProfiteAndMargin }))));
                        dispatch(updateTaxStatus(TAX_STATUS.IDLE));

                    }
                }

            } catch (error) {
                toast.error('Something went wrong!');
                dispatch(updateTaxStatus(TAX_STATUS.ERROR));
            }
        }
    };

    useEffect(() => {
        setTax(taxRate);
    }, [taxRate]);

    return (
        <section id='general'>
            <div className='top_content'>
                {/* Add expeses component */}
                <div className='left_col'>
                    <Expenses />
                </div>

                <div className='right_col'>
                    {/* Restaurant general data component */}
                    <RestaurantGeneralData />

                    <div className='tax_table table_container'>
                        <table>
                            <tbody>
                                <tr>
                                    <th>Include Taxes</th>
                                    <th><ToggleSwitch checked={showTax} setChecked={handleTaxCheck} /></th>
                                </tr>
                                {showTax && (
                                    <tr>
                                        <td>Tax Rate (%)</td>
                                        <td>
                                            <input type="text" name="tax" id="" value={tax} onChange={(e) => setTax(e.target.value)} onBlur={handleSubmitTax} placeholder='% Enter Amount' />
                                            {taxError && <span className='helper_text'>{taxError}</span>}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {/* Add Subrecipies */}
            <div className="mid_content">
                <SubRecipe />
            </div>

            {/* Add Ingredient component in the firebase */}
            <div className="bottom_content">
                <Ingredient />
            </div>
        </section>
    );
};

export default General;
