import React, { useState, useRef } from 'react';
import Auth from './auth';
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { addRestuarantInfo } from "../state/features/authSlice";
import { signinWithGoogle } from "../firebase/firestore/auth";
import useScrollDisable from '../hooks/useScrollDisable';
import useOutsideClose from '../hooks/useOutsideClose';
import Loader from './Loader';

const AuthDialog = ({ isDialogOpen, setIsDialogOpen }) => {
  const [hanldlingGoogleSignin, setHandlingGoogleSingin] = useState(false);
  const dispatch = useDispatch();

  const [authPage, setAuthPage] = useState("signup");

  const dialogRef = useRef();

  let txt1, txt2, txt3, txt4;

  if (authPage === "signup") {
    txt1 = "Let's Get Started";
    txt2 = "Already have an account?";
    txt3 = "Sign in";
    txt4 = "Sign up";
  } else {
    txt1 = "Welcome back!";
    txt2 = "Don't have an account?";
    txt3 = "Sign up";
    txt4 = "Sign in";
  }

  const handleAuthPage = () => {
    authPage === "signup" ? setAuthPage("login") : setAuthPage("signup");
  };

  const handleSignWithGoogle = async () => {
    setHandlingGoogleSingin(true);
    const user = await signinWithGoogle();
    if (user) {
      dispatch(addRestuarantInfo({ uid: user.uid, email: user.email, accessToken: user.accessToken, restaurantName: user?.restaurantName, signedIn: true }));
      toast.success(`Welcome!!!`);
      setHandlingGoogleSingin(false);
      setIsDialogOpen(false);
    }
  };

  useScrollDisable(isDialogOpen);

  useOutsideClose(dialogRef, () => setIsDialogOpen(false));

  return (
    <div id='dialog' className={isDialogOpen ? 'open' : ''}>
      <div className="container dialog_centered">
        <div className='dialog_content' ref={dialogRef}>
          <div className='dialog_body'>
            <div>
              <div>
                <h2>Mozzo Menu</h2>
                <h3>Recipe Calculator</h3>
              </div>
              <div>
                <div className='img_group'>
                  <img src='/images/food.png' alt='food-img' />
                  <img src='/images/line.png' alt='line-img' />
                </div>
                <ul>
                  <li>
                    <h4>Croutons 40gr</h4>
                    <h5>$0.20</h5>
                  </li>
                  <li>
                    <h4>Strawberries 20gr</h4>
                    <h5>$0.80</h5>
                  </li>
                  <li>
                    <h4>Mustard Sauce 30gr</h4>
                    <h5>$0.15</h5>
                  </li>
                  <li>
                    <h4>Lettuce 120gr</h4>
                    <h5>$0.40</h5>
                  </li>
                </ul>
              </div>
            </div>

            <div>
              <div>
                <h5>
                  {txt1}
                </h5>
                <div className='auth_option'>
                  <p>{txt2}</p>
                  <button
                    type='button'
                    className='link_btn'
                    onClick={handleAuthPage}
                  >
                    {txt3}
                  </button>
                </div>
                <button onClick={handleSignWithGoogle} className='btn outlined google_btn'>
                  <div className='flex'>
                    <img src="/images/google.png" alt='google-img' />
                    <span>
                      {txt4} with Google
                    </span>
                    {hanldlingGoogleSignin ? <Loader btnLoader={true} /> : null}
                  </div>
                </button>
              </div>
              <div className='divider'>
                <span>or</span>
              </div>
              <Auth authPage={authPage} setIsDialogOpen={setIsDialogOpen} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

};

export default AuthDialog;
