import React from 'react';

const TableField = ({ type, name, value, onChange, onBlur, adornment, placeholder }) => {
    return (
        <div className='table_field'>
            {adornment && <span>{adornment}</span>}
            <input
                type={type && type}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                placeholder={placeholder && placeholder}
            />
        </div>
    );
};

export default TableField;
