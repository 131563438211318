// Generate ingredient filter option from ingredients data for filtering functionlity
export const generateIngredientsSearchOptions = (ingradients) =>(ingradients.map((ingredient) => ({ value: ingredient.ingredient,label:ingredient.ingredient })))


// Generate sub-recipes filter option from ingredients data for filtering functionlity
export const generateSubRecipesSearchOptions = (subRecipes) =>(subRecipes.map((subRecipe) => ({ value: subRecipe.subRecipe,label:subRecipe.subRecipe })))


// Generate recipes filter option from ingredients data for filtering functionlity
export const generateRecipesSearchOptions = (recipes) =>(recipes.map((recipe) => ({ value: recipe.recipe,label:recipe.recipe })))


