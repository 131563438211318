import React from "react";

const Loader = ({ loaderBox, loaderSize, btnLoader }) => {
    return (
        <div className={loaderBox ? "loader_wrapper" : ""}>
            <div
                className={`loader ${btnLoader ? "btn_loader" : ""}`}
                style={loaderSize ? { width: loaderSize, height: loaderSize } : null}
            >
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export default Loader;
