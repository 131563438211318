import { createSlice } from "@reduxjs/toolkit";

export const GENERAL_DATA_STATUS = {
  LOADING: 'loading',
  IDLE : 'idle',
  ERROR : 'error'
}

export const initialGeneralValues = {
  data : {
    productSoldDaily : 0,
    totalEmployees: 0,
    daysWeeklyOpen: 1, 
  },

  generalDataIsEmpty: true,

  status: GENERAL_DATA_STATUS.LOADING,
}

const generalDataSlice = createSlice({
  name: "generalData",
  initialState: initialGeneralValues,
  reducers: {
    addGeneralData(state, action){
      const {payload} = action

      return {...state, data: {...payload}, generalDataIsEmpty: false}
    },
    generalDataStatus(state, action){
      return {...state, status : action.payload.status}
    },

    addInitalGeneralData(state, action){
      return {...action.payload}
    }
  },
});


export const { addGeneralData, generalDataStatus, addInitalGeneralData } = generalDataSlice.actions

export default generalDataSlice.reducer
 