import { createSlice } from "@reduxjs/toolkit";

export const TAX_STATUS = Object.freeze({
  IDLE: 'idle',
  ERROR: 'error',
  LOADING: 'loading'
})

export const taxInitialState = {
  showTax: false,
  taxRate: 0, 
  taxStatus: TAX_STATUS.IDLE
}

export const taxSlice = createSlice({
  name: 'taxSlice',
  initialState: taxInitialState,
  reducers: {
    addTax(state, action){
      return { ...state, taxRate : action.payload }
    },
    
    removeTax(state, action){
      return { taxRate : 0, showTax: false }
    },

    updateShowTax(state, action){
      return { ...state, showTax: action.payload }
    },

    updateTaxStatus(state, action){
      return { ...state, taxStatus: action.payload }
    }
  }
})

export const { addTax, removeTax, updateShowTax, updateTaxStatus } = taxSlice.actions

export default taxSlice.reducer