import React from 'react';

const ProfitInfo = ({ recipe }) => {

    return (
        <div className='bills_info table_container'>
            <table>
                <thead>
                    <tr>
                        <th>{recipe?.recipe}</th>
                        <th>Profit</th>
                    </tr>
                </thead>

                <tbody>
                    <tr className='sub_thead'>
                        <td>Total Cost</td>
                        <td>
                            <span>$</span>
                            <span>{recipe?.totalCost}</span>
                        </td>
                    </tr>

                    <tr className='sub_thead'>
                        <td>Selling Price</td>
                        <td>
                            <span>$</span>
                            <span>{recipe?.sellingPrice}</span>
                        </td>
                    </tr>

                    <tr>
                        <td>Gross Profit</td>
                        <td>
                            <span>$</span>
                            <span>{recipe?.profitDetails?.grossProfit}</span>
                        </td>
                    </tr>

                    <tr className='sub_thead'>
                        <td>Tax</td>
                        <td>
                            <span>$</span>
                            <span>{recipe?.profitDetails?.tax}%</span>
                        </td>
                    </tr>

                </tbody>

                <tfoot>
                    <tr>
                        <th>Net Profit</th>
                        <th>
                            <span>$</span>
                            <span> {recipe?.profitDetails?.netProfit} </span>
                        </th>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};

export default ProfitInfo;
