import React from 'react';
import Switch from "react-switch";

const ToggleSwitch = ({ checked, setChecked, name='' }) => {

    const handleChange = (nextChecked, name) => {
        setChecked(nextChecked,name);
    };

    return (
        <Switch
            onChange={(checked) =>handleChange(checked, name)}
            checked={checked}
            checkedIcon={false}
            uncheckedIcon={false}
            offColor="#fff"
            onColor="#54585b"
            className="react-switch"
            width={40}
            height={19}
            handleDiameter={17}
        />
    );
};

export default ToggleSwitch;
