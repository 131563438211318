import {createSlice} from '@reduxjs/toolkit'


export const unitInitalstate = {
  RestaurantMetricUnits : {
    select: true, 
    unitOptions: [
      { value: 'kilo', label: 'Kilo' },
      { value: 'liter', label: 'Liter' },
      { value: 'unitary', label: 'Unitary' }
    ]
  },

  RestaurantImperialUnits : {
    select: false, 
    unitOptions: [
      { value: 'pound', label: 'Pound' },
      { value: 'liter', label: 'Liter' },
      { value: 'unitary', label: 'Unitary' }
    ]
  },

}

export const unitSlice = createSlice({
  name: 'unit',
  initialState: unitInitalstate,
  reducers: {
    selectMetricUnits(state, action){
      return { RestaurantMetricUnits: { ...state.RestaurantMetricUnits, select: true }, RestaurantImperialUnits: { ...state.RestaurantImperialUnits, select: false } }
    },

    selectImperialUnits(state, action){
      return { RestaurantMetricUnits: { ...state.RestaurantMetricUnits, select: false }, RestaurantImperialUnits: { ...state.RestaurantImperialUnits, select: true } }
    },

  }

})


export const { selectMetricUnits, selectImperialUnits  } = unitSlice.actions;


export default unitSlice.reducer