import {createSlice} from '@reduxjs/toolkit'
import { defaultIngrdients } from '../../data/generalData'
import { calculateIngredientNetPrice, generateCategoriesOptions } from '../../utils/calculations'

export const INGREDIENT_STATUS = Object.freeze({
  IDLE : 'idle',
  LOADING : 'loading',
  ERROR : 'error'
})



export const ingredientInitialState = {
  data : calculateIngredientNetPrice(defaultIngrdients),
  categories: generateCategoriesOptions(defaultIngrdients)
}


export const ingredientSlice = createSlice({
  name: 'ingredientData',
  initialState: ingredientInitialState,
  reducers: {
    addIngredients(state, action){
      const {payload} = action
      return {...state, data : [...payload.ingredients]}
    },

    addIngredientsCategories(state, action){
      return { ...state, categories: action.payload }
    },

    initialIngredientList(state, action){
      return {...state, data : [...action.payload]}
    }
  }
})


export const { addIngredients, initialIngredientList, addIngredientsCategories } = ingredientSlice.actions;


export default ingredientSlice.reducer