import React from "react";
import Login from "./Login";
import Signup from "./Signup";

const Auth = ({ authPage, setIsDialogOpen }) => {
  if (authPage === "signup") {
    return <Signup setIsDialogOpen={setIsDialogOpen} />;
  } else {
    return <Login setIsDialogOpen={setIsDialogOpen} />;
  }
};


export default Auth;
