import { createSlice } from "@reduxjs/toolkit";

export const RECIPE_STATUS = {
  IDLE : 'idle',
  LOADING : 'loading',
  ERROR : 'error'
}

export const recipeInitialState = {
  data : [
  {
    recipe:  "",
    category: '',
    ingredients : [ 
      {unit: '', ingredient: '', weight: ''}],
    preparationTime: null,
    cookingTime: null, 
    ingredientsCost: null,
    variableCost: null,
    totalCost:null,
    sellingPrice: null,
    profit: null,
    margin: null,
    profitDetails: null,
    createdAt : new Date().toUTCString()
  }],

  categories: []
}

export const recipeSlice = createSlice({
  name: 'recipe',
  initialState: recipeInitialState,
  reducers: {
    addReceipes(state, action){
      const { payload } = action
      return { ...state, data : [...payload.recipes] }
    },

    addReceipesCategories(state, action){
      return { ...state, categories: [...action.payload] }
    },

    addInitialRecipes(state, action){
      return { ...action.payload }
    }
  }
})

export const { addReceipes, addInitialRecipes, addReceipesCategories } = recipeSlice.actions

export default recipeSlice.reducer



