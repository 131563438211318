import * as Yup from "yup";

export const signupSchema = Yup.object({
  name: Yup.string().required("*Required"),
  email: Yup.string().email("*Enter valid email").required("*Required"),
  password: Yup.string()
    .min(8, "*Password must be at least 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      "*Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
    )
    .required("*Required")
});

export const signinSchema = Yup.object({
  email: Yup.string().email("*Enter valid email").required("*Required"),
  password: Yup.string()
    .min(8, "*Password must be at least 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      "*Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
    )
    .required("*Required")
});




export const recipesSchema = Yup.object({
  recipes: Yup.array().of(
    Yup.object({
      recipe: Yup.string(),
      category: Yup.string(),
      ingredients: Yup.array().of(
        Yup.object({
          ingredient : Yup.string(),
          weight: Yup.number().nullable().transform(value => (isNaN(value) ? null : value)).typeError('Value should be a number'),
          unit : Yup.string()
        })
      ),
      preparationTime: Yup.number().nullable().transform(value => (isNaN(value) ? null : value)).typeError('Value should be a number'),
      cookingTime: Yup.number().nullable().transform(value => (isNaN(value) ? null : value)).typeError('Value should be a number'),
      // totalCost: Yup.number().nullable().transform(value => (isNaN(value) ? null : value)).typeError('Value should be a number'),
      sellingPrice: Yup.number().nullable().transform(value => (isNaN(value) ? null : value)).typeError('Value should be a number'),
    })
  ),
});



export const ingredientSchema = Yup.object({
  ingredients: Yup.array().of(
    Yup.object({
      ingredient: Yup.string(),
      category: Yup.string(),
      unit: Yup.string(),
      price: Yup.number().nullable().transform(value => (isNaN(value) ? null : value)).typeError('Price must be a number'),
      waste_rate: Yup.number().nullable().transform(value => (isNaN(value) ? null : value)).typeError('Waste rate must be a number'),
    })
  ),
});

export const subRecipesSchema = Yup.object({
  subRecipes: Yup.array().of(
    Yup.object({
      subRecipe: Yup.string(),
      ingredients: Yup.array().of(
        Yup.object({
          ingredient : Yup.string(),
          weight: Yup.number().nullable().transform(value => (isNaN(value) ? null : value)).typeError('Value should be a number'),
          unit : Yup.string()
        })
      ),
      unit: Yup.string(),
      output: Yup.number()
        .nullable()
        .transform(value => (isNaN(value) ? null : value))
        .typeError('Value should be a number'),
      preparationTime: Yup.number()
        .nullable()
        .transform(value => (isNaN(value) ? null : value))
        .typeError('Value should be a number'),
      cookingTime: Yup.number()
        .nullable()
        .transform(value => (isNaN(value) ? null : value))
        .typeError('Value should be a number'),
    })
  ),
});

export const expensesSchema = Yup.object({
  payroll: Yup.number()
    .nullable()
    .transform(value => (isNaN(value) ? null : value))
    .moreThan(0, 'Value must be greater than 0')
    .typeError('Value should be a number'),

  rent: Yup.number()
    .nullable()
    .transform(value => (isNaN(value) ? null : value))
    .moreThan(0, 'Value must be greater than 0')
    .typeError('Value should be a number'),

  water: Yup.number()
    .nullable()
    .transform(value => (isNaN(value) ? null : value))
    .moreThan(0, 'Value must be greater than 0')
    .typeError('Value should be a number'),

  electricity: Yup.number()
    .nullable()
    .transform(value => (isNaN(value) ? null : value))
    .moreThan(0, 'Value must be greater than 0')
    .typeError('Value should be a number'),

  gas: Yup.number()
    .nullable()
    .transform(value => (isNaN(value) ? null : value))
    .moreThan(0, 'Value must be greater than 0')
    .typeError('Value should be a number'),

  other: Yup.array().of(
    Yup.object({
      bill: Yup.string(),
      cost: Yup.number()
        .nullable()
        .transform(value => (isNaN(value) ? null : value))
        .moreThan(0, 'Value must be greater than 0')
        .typeError('Value should be a number'),
    })
  )
});

export const generalDataSchema = Yup.object({
  productSoldDaily: Yup.number().nullable().transform(value => (isNaN(value) ? null : value)).moreThan(0, 'Value must be greater than 0')
  .typeError('Value should be a number').required('Required'),

  totalEmployees: Yup.number()
    .nullable()
    .transform(value => (isNaN(value) ? null : value))
    .moreThan(0, 'Value must be greater than 0')
    .typeError('Value should be a number').required('Required'),

    daysWeeklyOpen: Yup.number()
    .nullable()
    .transform(value => (isNaN(value) ? null : value))
    .moreThan(0, 'Value must be greater than 0')
    .typeError('Value should be a number'),

});



export function numberValidationSchema(obj){
  const validateObj = {};

  for (const key in obj) {
    validateObj[key] = Yup.number()
      .moreThan(0, 'Value must be greater than 0')
      .typeError('Please enter a valid number')
      .integer('Please enter a valid number')
      .required('Value should be a valid number')
      .test('is-valid-number', 'Please enter a valid number', (value) => {
        // Additional custom validation if needed
        return value === null || value === undefined || !isNaN(value);
      });
  }


  return Yup.object(validateObj)

}