import React from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
// import { signinSchema } from "../../utils/formSchemas";
import {
  checkUserAuth,
  getRestaurantInfo,
  signinByFields,
} from "../../firebase/firestore/auth";
import { useDispatch, useSelector } from "react-redux";
import { addRestuarantInfo } from "../../state/features/authSlice";
import Loader from "../Loader";

const Login = ({ setIsDialogOpen }) => {
  const dispatch = useDispatch();
  const { accessToken } = useSelector(
    (state) => state.authSlice
  );

  const { handleSubmit, handleChange, values, errors, isSubmitting } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
      },
      // validationSchema: signinSchema,
      onSubmit: async (values, { resetForm }) => {
        try {
          const user = await signinByFields(values.email, values.password);
          if (user) {
            const res = await getRestaurantInfo(user.uid);
            if (res) {
              dispatch(
                addRestuarantInfo({ uid: user.uid, email: user.email, accessToken: user.accessToken, restaurantName: res.restaurantName, signedIn: true })
              );
              // check user and update general data, expenses, sub-recipes and recipes
              dispatch(checkUserAuth());
              localStorage.setItem("accessToken", accessToken);
              toast.success("Loggedin Successfully!");
              resetForm();
              setIsDialogOpen(false);
            }
          }
        } catch (error) {
          // console.log(error.message, "login comp");
        }
      },
    });

  return (
    <form onSubmit={handleSubmit} className="formbox">
      <div>
        <label>Email</label>
        <div className="input_box">
          <input type="email" placeholder="Email" name="email" value={values.email} onChange={handleChange} />
        </div>
        <span className="helper_text">{errors.email}</span>
      </div>

      <div>
        <label>Password</label>
        <div className="input_box">
          <input type="password" placeholder="Password" name="password" value={values.password} onChange={handleChange} />
        </div>
        <span className="helper_text">{errors.password}</span>
        <div className="forgot_pass" textAlign="right">
          <Link to="/">Forgot my password?</Link>
        </div>
      </div>

      <div>
        <button type="submit" className="btn" disabled={isSubmitting} >
          {isSubmitting ? <Loader btnLoader={true} /> : "Sign In"}
        </button>
      </div>
    </form>
  );
};

export default Login;
